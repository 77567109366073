import { BrowserView, MobileView } from "react-device-detect";
import { Swiper, SwiperSlide } from "swiper/react";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { ApiService } from "../../services/apiServices";
import constants from "../../services/constants";
import Accordion from "react-bootstrap/Accordion";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
const FAQ = () => {
  const defaultImg = constants.DEFAULT_IMAGE;
  const [Faq, setfaq] = useState([]);
  const [loading, setLoading] = useState(false);
  const didMountRef = useRef(true);

  useEffect(() => {
    if (didMountRef.current) {
      faqData();
    }
    didMountRef.current = false;
  }, []);

  const faqData = () => {
    setLoading(true);
    ApiService.Commanfetchrequest("faq-featured").then((res) => {
    
      if (res?.status == "success") {
        setfaq(res?.faqData);
        setLoading(false);
      }
    });
  };
  return (
    <>
      <BrowserView>
        {loading == true ? (
          <>
            {/* Skeleton Start */}
            <section
              className="section-gap-md"
              style={{ background: "rgb(246, 247, 248)" }}
            >
              <div className="container-fluid">
                <div className="section-title section-center">
                  <h2>
                    <Skeleton width={"40%"} height={50} />
                  </h2>
                </div>

                <Accordion>
                  <Skeleton width={"100%"} height={50} />
                </Accordion>
                <Accordion>
                  <Skeleton width={"100%"} height={50} />
                </Accordion>
                <Accordion>
                  <Skeleton width={"100%"} height={50} />
                </Accordion>
              </div>
            </section>
            {/* Skeleton Start */}
          </>
        ) : (
          <>
            <section
              className="section-gap-md"
              style={{ background: "rgb(246, 247, 248)" }}
            >
              <div className="container-fluid">
                <div className="section-title section-center">
                  <h2>Frequently Asked Questions</h2>
                  <span class="shape-line">
                    <i class="icon-19"></i>
                  </span>
                </div>

                {Faq?.length > 0 ? (
                  <>
                    <div className="accordbox">
                    <Accordion defaultActiveKey="0" flush>
                      {Faq?.map((items, index) => {
                        return (
                          <>
                            <Accordion.Item eventKey={index.toString()}>
                              <Accordion.Header>
                                {items?.faq_title}
                              </Accordion.Header>
                              <Accordion.Body>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: items?.faq_description,
                                  }}
                                ></p>
                              </Accordion.Body>
                            </Accordion.Item>
                          </>
                        );
                      })}
                    </Accordion>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </section>
          </>
        )}
      </BrowserView>
      <MobileView>
        {loading == true ? (
          <>
           {/* Skeleton Start */}
           <section
              className="section-gap-md"
              style={{ background: "rgb(246, 247, 248)" }}
            >
              <div className="container-fluid">
                <div className="section-title section-center">
                  <h2>
                    <Skeleton width={"40%"} height={50} />
                  </h2>
                </div>

                <Accordion>
                  <Skeleton width={"100%"} height={50} />
                </Accordion>
                <Accordion>
                  <Skeleton width={"100%"} height={50} />
                </Accordion>
                <Accordion>
                  <Skeleton width={"100%"} height={50} />
                </Accordion>
              </div>
            </section>
            {/* Skeleton Start */}
          </>
        ) : (
          <>
            <section
              className="section-gap-md"
              style={{ background: "rgb(246, 247, 248)" }}
            >
              <div className="container-fluid">
                <div className="section-title section-center">
                  <h2>Frequently Asked Questions</h2>
                  <span class="shape-line">
                    <i class="icon-19"></i>
                  </span>
                </div>

                {Faq?.length > 0 ? (
                  <>
                    <Accordion defaultActiveKey="0" flush>
                      {Faq?.map((items, index) => {
                        return (
                          <>
                            <Accordion.Item eventKey={index.toString()}>
                              <Accordion.Header>
                                {items?.faq_title}
                              </Accordion.Header>
                              <Accordion.Body>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: items?.faq_description,
                                  }}
                                ></p>
                              </Accordion.Body>
                            </Accordion.Item>
                          </>
                        );
                      })}
                    </Accordion>
                  </>
                ) : (
                  ""
                )}
              </div>
            </section>
          </>
        )}
      </MobileView>
    </>
  );
};

export default FAQ;
