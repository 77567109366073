import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { ApiService } from "../../components/services/apiServices";
import Skeleton from "react-loading-skeleton";
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'
import { Helmet } from "react-helmet";
import constants from "../../components/services/constants";

function Gallery() {
  const didMountRef = useRef(true);
  const [gallery, setGallery] = useState([]);
  const [galleryImageUrl, setGalleryImageUrl] = useState("");
  const [loading, setLoading] = useState();
  const [pageContent, setpageContent] = useState({});
  const [headerImageUrl, setHeaderImageUrl] = useState('')

  useEffect(() => {
    if (didMountRef.current) {
      getPagesData()
      gallerydata();
    }
    didMountRef.current = false;
  }, []);

  const getPagesData = () => {
    const pageData = {
      slug: "gallery",
    };

    ApiService.Commanpostrequest("pages-content", pageData).then((res) => {
      if (res.status == "success") {
        setpageContent(res.data)
        setHeaderImageUrl(res.header_image_path)
      }
    });
  };
  const gallerydata = () => {
    setLoading(true);
    ApiService.Commanfetchrequest("gallery").then((res) => {
      if (res.status === "success") {
        setGallery(res.gallery);
        setGalleryImageUrl(res.gallery_img_url);
        setLoading(false);
      }
    });
  };

  return (
    <>
        <Helmet>
        <title>{pageContent.page_meta_title}</title>
        <meta name="description" itemprop="description" content={pageContent.page_meta_desc != null ? pageContent.page_meta_desc :"Dr Prashant Dwivedi"} />
        {pageContent.page_meta_keyword != null ?<meta name="keywords" content={pageContent.page_meta_keyword} />:"Dr Prashant Dwivedi"}
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={pageContent.page_meta_title} />
        <meta name="twitter:url" content={window.location.href} />
        <meta property="og:image"  content={pageContent.page_header_image ? headerImageUrl + pageContent.page_header_image: constants.META_DEFAULT_IMAGE}/>
        <meta property="og:url" content={window.location.href} />
        {pageContent.page_meta_desc != null ?<meta property="og:description" content={pageContent.page_meta_desc} />:"Dr Prashant Dwivedi"}
        <meta name="twitter:title" content={pageContent.page_meta_title} />
        {pageContent.page_meta_desc != null ?<meta name="twitter:description" content={pageContent.page_meta_desc} />:"Dr Prashant Dwivedi"}
        <meta property="twitter:image" content={pageContent.page_header_image ? headerImageUrl + pageContent.page_header_image: constants.META_DEFAULT_IMAGE}/>
      </Helmet>
      <Header />


      <div className="subheader" style={{ backgroundImage: `url(${headerImageUrl + pageContent.page_header_image})` }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <h1>Image Gallery</h1>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Image Gallery
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      {loading == true ? (
        <>
          <section className="mt-5">
            <div className="container-fluid">
              <div className="row">
                {[...Array(6)].map((_, index) => (
                  <>
                    <div className="col-lg-4" key={index}>
                      <Skeleton width={"100%"} height={300} />
                      <p className="text-center">
                        <Skeleton width={"50%"} height={20} />
                      </p>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          {gallery?.length > 0 ? (
            <>
              <section className="mt-5">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="galarybox">
                        <LightGallery
                          plugins={[lgThumbnail, lgZoom]}>
                          {gallery.map((value, index) => (
                            <a href={galleryImageUrl + value.gallery_image} key={index}>
                              <img
                                src={galleryImageUrl + value.gallery_image}
                                alt=""
                                width={"100%"}
                                height={"385px"}
                              />
                              <p className="text-center" style={{ color: "#C1272D" }}>{value.gallery_title}</p>
                            </a>
                          ))}
                        </LightGallery>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </>
          ) : (
            ""
          )}
        </>
      )}

      <Footer />
    </>
  );
}

export default Gallery;
