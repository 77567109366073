import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Accordion } from "react-bootstrap";
import Book_AppointmentModal from "../../components/Elements/Modals/book_appointment";
import { ApiService } from "../../components/services/apiServices";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import constants from "../../components/services/constants";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import HomeVideo from "../../components/Elements/sections/home_video";
import SuccessStories from "../../components/Elements/sections/success_stories";
import CrossingBoundries from "../../components/Elements/sections/crossing_boundries";
import Home_Bookappointment from "../../components/Elements/sections/home_book_appointment";

function CardiologistJaipur() {
  const didMountRef = useRef(true);
  const [pageContent, setpageContent] = useState({});
  const [testimonialdata, settestimonialdata] = useState([]);
  const [testimonialImagepath, setTestimonialImagepath] = useState("");
  const [Faq, setfaq] = useState([])
  const [headerImageUrl, setHeaderImageUrl] = useState("");
  const [bookAppointmentModal, setBookAppointmentModal] = useState(false);
  const handleClose = () => {
    setBookAppointmentModal(false);
  };
  const defaultImg = constants.DEFAULT_IMAGE;
  const [Treatmentdata, setTreatmentdata] = useState([]);
  const [ImgUrl, setImgUrl] = useState("");
  const [loading, setLoading] = useState();
  const navigate = useNavigate();


  useEffect(() => {
    if (didMountRef.current) {
      getPagesData();
      treatmentList();
    }
    didMountRef.current = false;
  });
  const getPagesData = () => {
    const pageData = {
      slug: "cardiologist-in-jaipur",
    };

    ApiService.Commanpostrequest("pages-content", pageData).then((res) => {
      if (res.status == "success") {
        setpageContent(res.data);
        setHeaderImageUrl(res.header_image_path);
        setfaq(res.pageFaqData)
        settestimonialdata(res?.testimonialData);
        setTestimonialImagepath(res?.testimonial_image_url);
      }
    });
  };
  const treatmentList = () => {
    setLoading(true);
    ApiService.Commanfetchrequest("treatmentsfeatured").then((res) => {
      if (res?.status == "success") {
        setTreatmentdata(res?.data);
        setImgUrl(res?.IMAGE_URL);
        setLoading(false);
      }
    });
  };
  const generateRatingIcons = (rating) => {
    const icons = [];
    for (let i = 0; i < rating; i++) {
      icons.push(<i key={i} className="icon-23"></i>);
    }
    return icons;
  };

  return (
    <>
      <Helmet>
        <title>{pageContent.page_meta_title}</title>
        <meta name="description" itemprop="description" content={pageContent.page_meta_desc != null ? pageContent.page_meta_desc : "Dr Prashant Dwivedi"} />
        {pageContent.page_meta_keyword != null ? <meta name="keywords" content={pageContent.page_meta_keyword} /> : "Dr Prashant Dwivedi"}
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={pageContent.page_meta_title} />
        <meta name="twitter:url" content={window.location.href} />
        <meta property="og:image" content={pageContent.page_header_image ? headerImageUrl + pageContent.page_header_image : constants.META_DEFAULT_IMAGE} />
        <meta property="og:url" content={window.location.href} />
        {pageContent.page_meta_desc != null ? <meta property="og:description" content={pageContent.page_meta_desc} /> : "Dr Prashant Dwivedi"}
        <meta name="twitter:title" content={pageContent.page_meta_title} />
        {pageContent.page_meta_desc != null ? <meta name="twitter:description" content={pageContent.page_meta_desc} /> : "Dr Prashant Dwivedi"}
        <meta property="twitter:image" content={pageContent.page_header_image ? headerImageUrl + pageContent.page_header_image : constants.META_DEFAULT_IMAGE} />
      </Helmet>
      <BrowserView>
        <Header />
        <div
          className="subheader"
          style={{
            backgroundImage: `url(${headerImageUrl + pageContent.page_header_image
              })`,
          }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <h1>{pageContent.page_name}</h1>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {pageContent.page_name}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <section className="section-gap-sm">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7">
                <div className="section-title mb-3">
                  <h3>The Best Cardiologist in Jaipur - Dr. Prashant Dwivedi</h3>
                </div>
                <p>With a reputation that precedes him, Dr. Prashant Dwivedi is a well-known and the <strong>best Cardiology Doctor in Jaipur</strong>. With
                  more than 15 years of experience under his belt as an Interventional Cardiologist, he has successfully saved the life of many with his skills and experience. His expertise is
                  in a wide range of areas in the field of cardiology, that sets him apart from his peers. As a highly skilled Cardiologist, he brings his expertise to the role of Director &amp; Incharge
                  TAVR and Structural Heart Program and Consultant Interventional Cardiology at Eternal Hospital. With his experience and expansive knowledge, he has been impacting the
                  lives of his patients positively. </p>
                <p> A Gold Medalist in D.M (Cardiology) and M.D (Internal Medicine), Dr. Dwivedi specializes in a wide range of procedures that includes TAVI/TAVR, TMVR, MITRA-CLIP,
                  TRI-CLIP, Complex Coronary Angioplasty and PFO/ASD/VSD/PDA closure, and more. With his expertise and knowledge, he not only excels as a Cardiologist but also has become a guiding
                  light for many aspiring Cardiologists.</p>
                <div className="mt-4" style={{ display: "flex" }}>
                  <a className="edu-btn btn-medium me-3" href="/about" style={{ width: "220px" }}>Know More <i className="icon-4"></i></a>
                  <a className="edu-btn btn-medium" href="javascript:void(0)" onClick={() => setBookAppointmentModal(true)}>Book Appointment <i className="icon-4"></i></a>
                </div>
              </div>
              <div className="col-lg-5">
                <img
                  src="/images/cardiologist.png"
                  alt="best Cardiologist Specialist in Jaipur"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          </div>
        </section>
        <section className='section-gap-sm bg-lighten03'>
          <div className='container'>
            <div className="accordbox">
              <Accordion defaultActiveKey="0" flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Academic Record</Accordion.Header>
                  <Accordion.Body>
                    <div className="row">
                      <div className="col-md-6">
                        <p className="mb-4">
                          <b>
                            Fellowship Structural Heart Intervention, 2021-2022
                          </b>{" "}
                          <br />
                          Mount Sinai Hospital, New York, USA
                        </p>
                      </div>
                      <div className="col-md-6">
                        <p className="mb-4">
                          <b>Fellowship Interventional Cardiology, 2020-2021</b>{" "}
                          <br />
                          Mount Sinai Hospital, New York, USA
                        </p>
                      </div>
                      <div className="col-md-6">
                        <p className="mb-4">
                          <b>Fellowship DM Cardiology - 2013 – 2016</b> <br />
                          King George Medical University, Lucknow, India
                        </p>
                      </div>
                      <div className="col-md-6">
                        <p className="mb-4">
                          <b>Senior Residency, Internal Medicine - 2012- 2013</b>{" "}
                          <br />
                          M.G.M Medical College &amp; DAVV, Indore, India
                        </p>
                      </div>
                      <div className="col-md-6">
                        <p className="mb-4">
                          <b>MD (Residency), Internal Medicine - 2009- 2012</b>{" "}
                          <br />
                          M.G.M Medical College &amp; DAVV, Indore, India
                        </p>
                      </div>
                      <div className="col-md-6">
                        <p className="mb-4">
                          <b>Internship – 2008 - 2009</b> <br />
                          M.G.M Medical College &amp; DAVV, Indore, India
                        </p>
                      </div>
                      <div className="col-md-6">
                        <p className="mb-4">
                          <b>MBBS - 2003 - 2008</b> <br />
                          M.G.M Medical College &amp; DAVV, Indore, India
                        </p>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Work Experience</Accordion.Header>
                  <Accordion.Body>
                    <div className="field-body">
                      <p className="mb-4">
                        <b>
                          In charge &amp; Head, TAVR and Structural Heart Program,
                          Consultant Interventional Cardiology, since December
                          2022
                        </b>{" "}
                        <br />
                        Eternal Hospital and Research Institute, Jaipur, India
                      </p>
                      <p className="mb-4">
                        <b>
                          Associate Director TAVR and Structural Heart Program,
                          Consultant Interventional Cardiology, since July 2022
                        </b>{" "}
                        <br />
                        Eternal Hospital and Research Institute, Jaipur, India
                      </p>
                      <p className="mb-4">
                        <b>Consultant, Interventional Cardiology, 2016 – 2021</b>
                        <br />
                        Eternal Hospital and Research Institute, Jaipur, India
                      </p>
                      <p className="mb-4">
                        <b>
                          Proctor for TAVR across India &amp; International (MyVal
                          &amp; Medtronic Evolut){" "}
                        </b>{" "}
                        <br />
                        Eternal Hospital and Research Institute, Jaipur, India
                      </p>
                      <p className="mb-4">
                        <b>
                          Proctor for Rotational Atherectomy across India (Boston
                          Scientific)
                        </b>{" "}
                        <br />
                        Eternal Hospital and Research Institute, Jaipur, India
                      </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Awards</Accordion.Header>
                  <Accordion.Body>
                    <div className="field-body">
                      <p className="mb-4">Gold Medal in D.M (Cardiology)</p>
                      <p className="mb-4">Gold Medal in M.D (Internal Medicine)</p>
                      <p className="mb-4">
                        Best Case presentation in 6th Mid-term U.P Interventional
                        meet on 13/09/2015 organized at Lucknow by U.P Chapter,
                        Cardiology Society of India.
                      </p>
                      <p className="mb-4">
                        Achieved highest marks in D.M Cardiology in King George`s
                        Medical University, Lucknow, India
                      </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Achievements</Accordion.Header>
                  <Accordion.Body>
                    <div className="field-body">
                      <p className="mb-4">
                        Among very few cardiologists in India who has been
                        dedicatedly trained in TAVR, TMVR, Mitra-Clip, Tri-Clip
                        and other structural heart interventions
                      </p>
                      <p className="mb-4">
                        Performed TMVR in Ring preceded by Alcohol septal ablation
                        with Sapien 3 Valve – First case in India
                      </p>
                      <p className="mb-4">
                        Performed open Trans atrial TMVR in MAC with
                        balloon-expandable valve (My-Val) - First case in India
                        (July 27, 2022)
                      </p>
                      <p className="mb-4">
                        Performed TAVR on the oldest patient in India (104 years
                        old) and 3rd oldest patient in the world (August 02, 2022)
                      </p>
                      <p className="mb-4">
                        Performed TAVR and Leadless Micra (Dual sensing) in same
                        sitting as a second case in India (February 23, 2022)
                      </p>
                      <p className="mb-4">
                        Achieved highest marks / Gold medal in D.M Cardiology in
                        King George`s Medical University, Lucknow, India
                      </p>
                      <p className="mb-4">
                        Awarded Gold medal by Mahatma Gandhi Memorial Medical
                        College, DAVV University, Indore, India for Best PG in
                        Internal Medicine
                      </p>
                      <p className="mb-4">
                        Best Case presentation in Interventional meet on September
                        13, 2015, by the cardiology society of India
                      </p>
                      <p className="mb-4">
                        Educational Commission for Foreign Medical Graduates
                        (ECFMG) Certified – December 18, 2019
                      </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>Area Of Expertise</Accordion.Header>
                  <Accordion.Body>
                    <div classname="field-body">
                      <p className="mb-4">
                        Transcatheter Aortic Valve Replacement (TAVR/TAVI)-
                        Experience of more than 300 TAVR cases, including Bicuspid
                        valve, Valve in Valve, TAVR in TAVR
                      </p>
                      <p className="mb-4">
                        Transcatheter Edge to Edge repair (TEER) - One of the very
                        few cardiologists in India having expertise in Mitra-Clip,
                        Tri-Clip
                      </p>
                      <p className="mb-4">TMVR, TPVR, TTVR</p>
                      <p className="mb-4">
                        Complex coronary angioplasty including Impella,
                        Rotablation, Orbital atherectomy, LASER, IVL
                      </p>
                      <p className="mb-4">Left Atrial Appendage device closure</p>
                      <p className="mb-4">Paravalvular Leak closure</p>
                      <p className="mb-4">Alcohol Septal ablation</p>
                      <p className="mb-4">PFO/ASD/VSD/PDA/RSOV device closure</p>
                      <p className="mb-4">BMV/BAV/BPV/Coarctoplasty</p>
                      <p className="mb-4">
                        Peripheral intervention including Carotid, Renal, and
                        Ileo-femoral intervention
                      </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>Presentations</Accordion.Header>
                  <Accordion.Body>
                    <div className="field-body">
                      <p className="mb-4">
                        Case presented on “A case of stuck un-deflated coronary
                        balloon” in the “10th India Live Interventional Cardiology
                        Conference” on 2nd March 2019, organized by the
                        Interventional cardiology foundation of India (ICFI)
                      </p>
                      <p className="mb-4">
                        Case presented on “A case of futility…...never give up!! A
                        case of VT storm with rescue PCI of LM bifurcation in
                        ongoing CPR and recurrent DC shock” in the “10th India
                        Live Interventional Cardiology Conference” on 1st March
                        2019, organized by Interventional cardiology foundation of
                        India (ICFI)
                      </p>
                      <p className="mb-4">
                        Challenging case presented on “Maze of Haze – A case of
                        ECMO &amp; IABP assisted Rota PCI of LM bifurcation in
                        ACS” in ‘‘Transcatheter Cardiovascular Therapeutics [TCT]
                        2018 conference’’ held at San Diego, USA from 21st to 25th
                        September 2018, organized by Cardiovascular Research
                        Foundation
                      </p>
                      <p className="mb-4">
                        Case presented on “Valve in valve Transcatheter Mitral
                        Valve Replacement (TMVR)” in the “India Valve 2018
                        Conference” held in New Delhi, India from 31st August to
                        2nd September 2018, organized by Heart Valve Foundation of
                        India
                      </p>
                      <p className="mb-4">
                        Case presented on “Rota-ablation guided complex PCI to LCX
                        and RCA” in 8th India Live Interventional Cardiology
                        conference on 3rd March 2017 organized by Interventional
                        cardiology foundation of India (ICFI)
                      </p>
                      <p className="mb-4">
                        Case presented on “ECMO assisted Rota-ablation PCI of LMCA
                        and RCA” in 8th India Live Interventional Cardiology
                        conference on 2nd March 2017 organized by Interventional
                        cardiology foundation of India (ICFI)
                      </p>
                      <p className="mb-4">
                        Case presented on “Unusual wire entrapment with fracture
                        during PCI “in 6th Midterm U.P Interventional meet on 13th
                        September 2015 organized at Lucknow by U.P chapter,
                        cardiology society of India
                      </p>
                      <p className="mb-4">
                        Case presented on Scleroderma in: “Workshop of
                        Rheumatology”, March 2012 organized by Department of
                        Medicine, MGM Medical College, Indore, India
                      </p>
                      <p className="mb-4">
                        Case presented in 3rd Annual Refresher course in:
                        “Clinical Examination and Interpretation” January 2012
                        organized by Department of Medicine, MGM Medical College,
                        Indore, India
                      </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>
                    Publications & Research & Experience
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="field-body">
                      <p className="mb-4">
                        Paper publication on “ Efficacy and Safety of an Upfront
                        RotaTripsy Strategy in the Treatment of DeNovo and
                        In-stent Restenosis” in J INVSIVE CARDIOL 2023;35(2):
                        E70-E74.Epub 2023 January 6.
                      </p>
                      <p className="mb-4">
                        Paper publication on “Single centre experience of 105
                        minimalistic transfemoral transcatheter aortic valve
                        replacement and its outcome” in Indian Heart Journal
                        Volume 73, Issue 3, May-June 2021
                      </p>
                      <p className="mb-4">
                        Paper publication on “Bifurcation Stenting: Basics and
                        Beyond” in NIC newsletter organized by National
                        interventional council &amp; Cardiology Society of India,
                        held on 05th to 7th April 2019 at Lucknow, India
                      </p>
                      <p className="mb-4">
                        Paper publication on “Biochemical and blood profile
                        amongst hypertensive subjects reporting at a tertiary care
                        hospital” in International Journal of Medical Research
                        Professionals, DOI: 10.21276/ijmrp.2018.4.6.033
                      </p>
                      <p className="mb-4">
                        Worked as Sub Investigator for Clinical Research protocol:
                        A Multicenter, randomized, double-blind,
                        placebo-controlled, 5-arm, parallel-group study to assess
                        Rotigotine transdermal system dose response in subjects
                        with advanced-stage Parkinson`s disease
                      </p>
                      <p className="mb-4">
                        Worked as Sub Investigator for Clinical Research protocol:
                        A Multicenter “IMPROVE BRADY” trial (study)
                      </p>
                      <p className="mb-4">
                        Worked as Sub Investigator for Clinical Research protocol:
                        A Multicenter “IMPROVE SCA” trial (study)
                      </p>
                      <p className="mb-4">
                        Performed Extensive Clinical Research in the field of
                        Systemic Hypertension. The research work was submitted as
                        an MD thesis entitled “Assessment of endothelial
                        dysfunction by Flow Mediated Dilation of the brachial
                        artery in young patients of systemic hypertension” in
                        M.G.M Medical College, DAVV University Indore, India
                      </p>
                      <p className="mb-4">
                        Performed Extensive Imaging and Clinical Research in the
                        field of Pulmonary Arterial Hypertension. The research
                        work was submitted as a D.M thesis entitled “Assessment of
                        short-term effects of phosphodiesterase -5 inhibitors in
                        patients with secondary pulmonary hypertension.” in King
                        George's Medical University, Lucknow, India
                      </p>
                      <p className="mb-4">
                        Abstract publication on “Assessment of short-term effects
                        of sildenafil therapy in patients with secondary pulmonary
                        hypertension” in Indian Heart Journal 67 (2015) S 124
                      </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <Accordion.Header>Teaching Experience</Accordion.Header>
                  <Accordion.Body>
                    <div className="field-body">
                      <span>
                        <strong>
                          Mahatma Gandhi Memorial Medical College, Indore, India
                        </strong>
                      </span>
                      <p className="mb-4">
                        Worked as a postgraduate cum tutor and was actively
                        involved in teaching medical and dental undergraduates
                        from 2009 to 2012
                      </p>
                      <p className="mb-4">
                        Worked as a senior resident cum tutor and was actively
                        involved in teaching postgraduate students of Internal
                        medicine from 2012 to 2013
                      </p>
                      <span>
                        <strong>
                          King George`s Medical University, Lucknow, India
                        </strong>
                      </span>
                      <p className="mb-4">
                        Worked as Cardiology Fellow cum tutor and was actively
                        involved in teaching medical undergraduates and
                        postgraduates from 2013 to 2016
                      </p>
                      <span>
                        <strong>Mount Sinai Hospital, New York, USA</strong>
                      </span>
                      <p className="mb-4">
                        Worked as Interventional cardiology / Structural heart
                        fellow cum tutor and was actively involved in teaching for
                        General Cardiology fellow from 2021 to June 2022
                      </p>
                      <span className>
                        <strong>Eternal Hospital Jaipur, India</strong>
                      </span>
                      <p className="mb-4">
                        Working as teaching faculty for DNB Cardiology fellow
                        since September 2019 to date
                      </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                  <Accordion.Header> Membership</Accordion.Header>
                  <Accordion.Body>
                    <div className="field-body">
                      <p className="mb-4">
                        Fellow American College of Cardiology (FACC / FIT)
                      </p>
                      <p className="mb-4">
                        Lifetime Member of the Cardiology Society of India (CSI)
                        (L-4245)
                      </p>
                      <p className="mb-4">
                        International Associate, Society of Cardiovascular
                        Angiography and Interventions (SCAI)
                      </p>
                      <p className="mb-4">
                        Member of the European Society of Cardiology (ESC)
                        (722180)
                      </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>

          </div>
        </section>

        <HomeVideo />

        {Treatmentdata?.length > 0 ? (
          <>
            <section className="section-gap-sm ">
              <div className="container">
                <div className="section-title section-center">
                  <h2>Our Treatments</h2>
                  <span className="shape-line">
                    <i className="icon-19"></i>
                  </span>
                  <p>
                    We provide best cardic care, when you need it. Get
                    Personlized and high quality care for your heart
                    because.
                  </p>
                </div>
                <div className="row g-3 justify-content-center">
                  {Treatmentdata?.map((items, index) => {
                    return (
                      <>
                        <div className="col-lg-3" key={index}>
                          <div
                            className="treatbox"
                            onClick={() => {
                              navigate(`/treatments/${items?.slug}`);
                            }}
                          >
                            <div className="treatbox-img">
                              <img
                                src={
                                  items?.image
                                    ? ImgUrl + "/" + items?.image
                                    : defaultImg
                                }
                              ></img>
                            </div>
                            <div className="treatbox-title">
                              {items?.title}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </section>
          </>
        ) : (
          ""
        )}

        <section className="section-gap-sm bg-lighten03">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <img src="/images/ci/cardiimg5.jpg" className="img-fluid borderradius05" alt="Top Cardiologist in Jaipur"></img>
              </div>
              <div className="col-lg-6">
                <div className="section-title mb-3">
                  <h3>A Name Eminent in the Realm of Cardiology</h3>
                </div>
                <p><a href="https://drprashantdwivedi.com/" target="_blank">Dr. Prashant Dwivedi</a>
                  , the best Cardiologist in Jaipur, is a distinguished name in
                  the field of Cardiology as he has been known for performing some
                  of the complex heart procedures, including high-risk angioplasty
                  for challenging cases, Rotablation for Calcified Blockages, CTO
                  Angioplasty, Left Main Bifurcation Angioplasty, Impella-assisted
                  Angioplasty, IVL-assisted Angioplasty, and Peripheral
                  Angioplasty.
                </p>
                <p>
                  Dr. Dwivedi has become a prodigy in Cardiology as he is one of
                  the few cardiologists in India who is dedicatedly trained in
                  TAVR/MITRA-Clip/TRI-Clip/TMVR and other Structural Heart
                  Interventions. He possesses the specialization and expertise in
                  performing TAVR and other intricate valve and structural heart
                  interventions such as TMVR (Transcatheter Mitral Valve
                  Replacement), MitraClip procedure for Mitral Regurgitation
                  Repair, TPVR (Transcatheter Pulmonary Valve Replacement), TTVR
                  (Transcatheter Tricuspid Valve Repair), Alcohol Septal Ablation
                  for Hypertrophic Obstructive Cardiomyopathy treatment, Para
                  Valvular Leak Closure procedures, and closure of ASD/VSD/PDA/PFO
                  devices. Moreover, he also specializes in Left Atrial Appendage
                  Closure, among many other specialized procedures.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="section-gap-sm">
          <div className="container">
            <div className="section-title">
              <h3>What makes Dr. Prashant Dwivedi the Top cardiologist in Jaipur?</h3>
              <p>
                As a leading figure in the field of Cardiology, Dr. Dwivedi has
                become a top Cardiologist in Jaipur who specializes in
                Intervention Cardiology. Over the years, he has performed
                several minimally invasive procedures such as Complex&nbsp;
                <a
                  href="https://drprashantdwivedi.com/treatments/complex-coronary-angioplasty"
                  target="_blank"
                  style={{ color: "#c1272d" }}
                >
                  Coronary Angioplasty,
                </a>
                &nbsp; Rotablation, TAVR, Mitra Clip, TMVR (Transcatheter Mitral
                Valve Replacement), TPVR (Transcatheter Pulmonary Valve
                Replacement), TTVR, Pacemaker and ICD Implantation, and more
                that involves treating several heart diseases. What makes Dr.
                Dwivedi a trusted name in the field of Cardiology is because:
              </p>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <h5>An Expert TAVR Specialist:</h5>
                <p>
                  Dr. Prashant's reputation as a renowned Cardiologist specialist
                  in Jaipur is undeniable by the fact that he has an extensive
                  experience with over 450 TAVR cases. His expertise in the field
                  goes beyond&nbsp;
                  <a

                    href="https://drprashantdwivedi.com/treatments/transcatheter-aortic-valve-replacement"
                    target="_blank"
                    style={{ color: "#c1272d" }}
                  >
                    TAVR&nbsp;
                  </a>
                  as he has also performed several minimally invasive procedures
                  encompassing complex cases such as Bicuspid Valve, Valve in
                  Valve, and TAVR in TAVR, TMVR and more.
                </p>
                <h5>A Strong Educational Background:</h5>
                <p>
                  Dr. Dwivedi is considered at the forefront of notable
                  advancements in minimally invasive procedures in the domain of
                  Cardiology because of his strong educational background. His
                  credibility as a top Cardiology Doctor in Jaipur can be seen by
                  his association with King George's Medical University, Lucknow,
                  for D.M. Cardiology and Fellowship for Interventional Cardiology
                  and Structural Heart Disease at Mount Sinai Hospital New York,
                  USA. With several academic research papers and presentations, he
                  is committed to the latest developments in the field of
                  Cardiology. He is one of the very few cardiologist in India who
                  has been dedicatedly trained in Complex Coronary Intervention
                  and TAVR / Structural Heart Intervention from USA.
                </p>
                <h5>A Patient-Centric Approach:</h5>
                <p>
                  When it comes to providing exceptional care and treatment to
                  patients, Dr. Dwivedi stands true to his reputation as a trusted
                  Cardiologist Doctor near by providing the patients with the best
                  possible treatment and care. Being a cardiologist, he
                  understands that an operation going through a heart disease can
                  be overwhelmed with the treatment. That is why he ensures that
                  the patients are well-informed about the treatment while
                  maintaining empathy and communicating with care.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="section-gap-sm bg-lighten03">
          <div className="container">
            <div className="section-title">
              <h3>Keeping it Beating Strong with Dr. Prashant Dwivedi</h3>
              <p>
                Choosing Dr. Prashant Dwivedi as your healthcare provider offers a range of compelling reasons.
              </p>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <p>
                  Dr. Dwivedi possesses a wealth of experience and expertise in his field, ensuring top-notch care for
                  his patients.  His dedication to staying updated with the latest advancements in medical science
                  guarantees that you receive the most effective and innovative treatments available. He also
                  recommends his patients to undergo &nbsp;<a href="/blogs/cardiovascular-exercise-definition-examples-and-benefit" target="_blank">cardiovascular exercise</a>&nbsp; to ensure that their health remain
                  intact.
                </p>
                <p>
                  Moreover, Dr. Dwivedi is known for his compassionate approach, providing personalized care and
                  attention to each patient's needs. His strong communication skills foster trust and understanding,
                  making him an excellent choice for anyone seeking reliable medical care. He will also suggest the
                  patients with <a href="https://mysterioustrip.com/heart-ticking-exercise/" target="_blank">Top Exercises to Keep Your Heart Healthy </a> for their better overall health. Overall,
                  selecting Dr. Prashant Dwivedi ensures not only expertise but also empathy and support throughout
                  your healthcare journey.
                </p>
              </div>
            </div>
          </div>
        </section>

        <SuccessStories />
        <CrossingBoundries />
        <Home_Bookappointment />

        <section className="section-gap-md blog-section blog-section-second">
          <div className="container">
            <div className="section-title section-center">
              <h2 className="tx-white">Why choose Dr. Prashant Dwivedi?</h2>
              <p className="tx-white">With the world suffering from increasing heart diseases, having access to the <strong>Best Cardiologist in Jaipur</strong> is
                bliss. Dr. Prashant Dwivedi, being an expert in the field of cardiology, has become a top name for people seeking apt Cardiac care and treatment. You can interest your heart health in the
                expert hands of Dr. Dwivedi as he offers:</p>
            </div>
            <div className="row g-3">
              <div className="col-lg-4">
                <div className="blog blog-style-1">
                  <div className="thumbnail">
                    <a href="#"><img src="/images/cardiologist1.jpg" alt="Best Cardiologist in Jaipur" /></a>
                  </div>
                  <div className="content-seond">
                    <h5 className="title-seond text-center cardio-heading mb-3">Comprehensive Cardiac Care</h5>
                    <p>With a sound educational background and experience, Dr.Dwivedi is well-versed with the latest technology and equipment that ensures that you will get high-quality care
                      and safety for your heart. With his extensive knowledge in performing minimally invasive complex procedures such as Complex Angioplasty, Rotablation, TAVR, TMVR, Mitra Clip,
                      Paravalvular Leak Closure, and more, you are sure to get the best possible Cardiac Care.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="blog blog-style-1">
                  <div className="thumbnail">
                    <a href="#"><img src="/images/cardiologist2.jpg" alt="best Cardiologist Specialist in Jaipur" /></a>
                  </div>
                  <div className="content-seond">
                    <h5 className="title-seond text-center cardio-heading mb-3">Personalized Treatment</h5>
                    <p>
                      Every patient requires different cardiac care, and Dr.
                      Dwivedi, being the Best Cardiologist in Jaipur,
                      understands it. In his more than a decade-long experience
                      in the field, he has been providing patients with
                      personalized care with a patient-centric approach
                      addressing every concern related to the treatment. He
                      addresses a wide range of heart conditions and offers
                      personalized treatment options to the patients.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="blog blog-style-1">
                  <div className="thumbnail">
                    <a href="#"><img src="/images/cardiologist3.jpg" alt="Top Cardiologist in Jaipur" /></a>
                  </div>
                  <div className="content-seond">
                    <h5 className="title-seond text-center cardio-heading mb-3">A Highly- Acclaimed Cardiologist</h5>
                    <p>
                      Dr. Dwivedi's reputation as the Top Cardiologist in Jaipur
                      extends beyond the city. His expertise can be reckoned by
                      the fact that he has been invited actively to perform such
                      Complex heart procedures at well-recognized and renowned
                      hospitals and institutes all across the country as well as
                      Southeast Asia. These invitations were a testament to
                      office expertise and knowledge with TAVR (Transcatheter
                      Aortic Valve Replacement), TMVR, Mitra Clip Procedure, and
                      Complex Angioplasties.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='section-gap-sm 
       text-center'>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <p>
                  With his hard work and dedication, Dr. Prashant Dwivedi has
                  become a name synonymous with "Trust and Care" in the field
                  of cardiology. He has been continuously working in the field
                  of advancing Cardiac Care with a commitment to learning and
                  innovation, making him the Top Cardiologist in Jaipur.
                </p>
                <p>His quest for knowledge knows no bounds, as he continuously immerses himself in the latest
                  advancements and breakthroughs in the field. Dr. Dwivedi's thirst for innovation fuels his efforts to
                  push the boundaries of conventional treatments, always seeking newer, more effective approaches
                  to cardiac care.
                </p>
              </div>
            </div>
          </div>
        </section>
        <div className="counterup-area-5 section-gap-md">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div className="edu-counterup counterup-style-5 primary-color">
                  <h2 className="counter-item count-number">450+ </h2>
                  <h6 class="title">TAVR Still Counting</h6>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="edu-counterup counterup-style-5 secondary-color">
                  <h2 className="counter-item count-number">10+</h2>
                  <h6 class="title">Experience</h6>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="edu-counterup counterup-style-5 extra02-color">
                  <h2 className="counter-item count-number">1000+</h2>
                  <h6 class="title">
                    Rotablation / IVL / Orbital Atherectomy
                  </h6>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="edu-counterup counterup-style-5 extra05-color">
                  <h2 className="counter-item count-number"> 50+</h2>
                  <h6 class="title">
                    Impella assisted complex angioplasty
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className='section-gap-md' style={{ background: 'rgb(246, 247, 248)' }}>
          <div className='container'>
            <div className="section-title section-center">
              <h2>Frequently Asked Questions</h2>
              <span className="shape-line">
                <i className="icon-19"></i>
              </span>
            </div>

            {Faq?.length > 0 ? <>
              <div className="accordbox">
                <Accordion defaultActiveKey="0" flush>
                  {Faq?.map((items, index) => {
                    return (<>
                      <Accordion.Item eventKey={index.toString()}>
                        <Accordion.Header>{items?.pf_question}</Accordion.Header>
                        <Accordion.Body>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: items?.pf_answer,
                            }}
                          ></p>

                        </Accordion.Body>
                      </Accordion.Item>
                    </>)
                  })}

                </Accordion>
              </div>
            </> : ""}
          </div>
        </section>
        {testimonialdata.length > 0 && (
          <section
            className="section-gap-md"
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title section-center">
                    <h2>Patient Testimonial</h2>
                    <span className="shape-line">
                      <i className="icon-19"></i>
                    </span>
                  </div>
                  <div className="testiomnial">
                    <Swiper
                      spaceBetween={0}
                      slidesPerView={4}
                      onSlideChange={() => console.log("slide change")}
                      onSwiper={(swiper) => console.log(swiper)}
                      autoplay={{
                        delay: 2000,
                        disableOnInteraction: false,
                      }}
                      modules={[Autoplay]}
                      breakpoints={{
                        0: {
                          slidesPerView: 1,
                        },
                        400: {
                          slidesPerView: 1,
                        },
                        639: {
                          slidesPerView: 2,
                        },
                        865: {
                          slidesPerView: 2,
                        },
                        1000: {
                          slidesPerView: 4,
                        },
                        1500: {
                          slidesPerView: 4,
                        },
                        1700: {
                          slidesPerView: 4,
                        },
                      }}
                    >
                      {testimonialdata.map((value, index) => {
                        return (
                          <SwiperSlide key={index}>
                            <div className="testimonial-grid">
                              <div className="thumbnail">
                                <img
                                  src={
                                    value.testimonial_image != null
                                      ? testimonialImagepath +
                                      value.testimonial_image
                                      : "/images/testim.png"
                                  }
                                  alt="Testimonial"
                                />
                                <span className="qoute-icon">
                                  <i className="icon-26"></i>
                                </span>
                              </div>
                              <div className="content">
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: value.testimonial_desc,
                                  }}
                                ></p>
                                <div className="rating-icon">
                                  {generateRatingIcons(value.testimonial_rating)}
                                </div>
                                <h5 className="title">
                                  {value.testimonial_name}
                                </h5>
                                <span className="subtitle">
                                  {value.testimonial_post}
                                </span>
                              </div>
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        <Footer />
      </BrowserView>
      <MobileView>
        <Header />
        <div
          className="subheader"
          style={{
            backgroundImage: `url(${headerImageUrl + pageContent.page_header_image
              })`,
          }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <h1>{pageContent.page_name}</h1>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {pageContent.page_name}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <section className="section-gap-sm">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7">
                <div className="section-title mb-3">
                  <h3>The Best Cardiologist in Jaipur - Dr. Prashant Dwivedi</h3>
                </div>
                <p>With a reputation that precedes him, Dr. Prashant Dwivedi is a well-known and the <strong>best Cardiology Doctor in Jaipur</strong>. With
                  more than 15 years of experience under his belt as an Interventional Cardiologist, he has successfully saved the life of many with his skills and experience. His expertise is
                  in a wide range of areas in the field of cardiology, that sets him apart from his peers. As a highly skilled Cardiologist, he brings his expertise to the role of Director &amp; Incharge
                  TAVR and Structural Heart Program and Consultant Interventional Cardiology at Eternal Hospital. With his experience and expansive knowledge, he has been impacting the
                  lives of his patients positively. </p>
                <p> A Gold Medalist in D.M (Cardiology) and M.D (Internal Medicine), Dr. Dwivedi specializes in a wide range of procedures that includes TAVI/TAVR, TMVR, MITRA-CLIP,
                  TRI-CLIP, Complex Coronary Angioplasty and PFO/ASD/VSD/PDA closure, and more. With his expertise and knowledge, he not only excels as a Cardiologist but also has become a guiding
                  light for many aspiring Cardiologists.</p>
                <div className="mt-4" style={{ display: "flex" }}>
                  <a className="edu-btn btn-medium me-3" href="/about-us" style={{ width: "220px" }}>Know More <i className="icon-4"></i></a>
                  <a className="edu-btn btn-medium" href="javascript:void(0)" onClick={() => setBookAppointmentModal(true)}>Book Appointment <i className="icon-4"></i></a>
                </div>
              </div>
              <div className="col-lg-5">
                <img
                  src="/images/cardiologist.png"
                  alt="best Cardiologist Specialist in Jaipur"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          </div>
        </section>
        <section className='section-gap-sm bg-lighten03'>
          <div className='container'>
            <div className="accordbox">
              <Accordion defaultActiveKey="0" flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Academic Record</Accordion.Header>
                  <Accordion.Body>
                    <div className="row">
                      <div className="col-md-6">
                        <p className="mb-4">
                          <b>
                            Fellowship Structural Heart Intervention, 2021-2022
                          </b>{" "}
                          <br />
                          Mount Sinai Hospital, New York, USA
                        </p>
                      </div>
                      <div className="col-md-6">
                        <p className="mb-4">
                          <b>Fellowship Interventional Cardiology, 2020-2021</b>{" "}
                          <br />
                          Mount Sinai Hospital, New York, USA
                        </p>
                      </div>
                      <div className="col-md-6">
                        <p className="mb-4">
                          <b>Fellowship DM Cardiology - 2013 – 2016</b> <br />
                          King George Medical University, Lucknow, India
                        </p>
                      </div>
                      <div className="col-md-6">
                        <p className="mb-4">
                          <b>Senior Residency, Internal Medicine - 2012- 2013</b>{" "}
                          <br />
                          M.G.M Medical College &amp; DAVV, Indore, India
                        </p>
                      </div>
                      <div className="col-md-6">
                        <p className="mb-4">
                          <b>MD (Residency), Internal Medicine - 2009- 2012</b>{" "}
                          <br />
                          M.G.M Medical College &amp; DAVV, Indore, India
                        </p>
                      </div>
                      <div className="col-md-6">
                        <p className="mb-4">
                          <b>Internship – 2008 - 2009</b> <br />
                          M.G.M Medical College &amp; DAVV, Indore, India
                        </p>
                      </div>
                      <div className="col-md-6">
                        <p className="mb-4">
                          <b>MBBS - 2003 - 2008</b> <br />
                          M.G.M Medical College &amp; DAVV, Indore, India
                        </p>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Work Experience</Accordion.Header>
                  <Accordion.Body>
                    <div className="field-body">
                      <p className="mb-4">
                        <b>
                          In charge &amp; Head, TAVR and Structural Heart Program,
                          Consultant Interventional Cardiology, since December
                          2022
                        </b>{" "}
                        <br />
                        Eternal Hospital and Research Institute, Jaipur, India
                      </p>
                      <p className="mb-4">
                        <b>
                          Associate Director TAVR and Structural Heart Program,
                          Consultant Interventional Cardiology, since July 2022
                        </b>{" "}
                        <br />
                        Eternal Hospital and Research Institute, Jaipur, India
                      </p>
                      <p className="mb-4">
                        <b>Consultant, Interventional Cardiology, 2016 – 2021</b>{" "}
                        <br />
                        Eternal Hospital and Research Institute, Jaipur, India
                      </p>
                      <p className="mb-4">
                        <b>
                          Proctor for TAVR across India &amp; International (MyVal
                          &amp; Medtronic Evolut){" "}
                        </b>{" "}
                        <br />
                        Eternal Hospital and Research Institute, Jaipur, India
                      </p>
                      <p className="mb-4">
                        <b>
                          Proctor for Rotational Atherectomy across India (Boston
                          Scientific)
                        </b>{" "}
                        <br />
                        Eternal Hospital and Research Institute, Jaipur, India
                      </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Awards</Accordion.Header>
                  <Accordion.Body>
                    <div className="field-body">
                      <p className="mb-4">Gold Medal in D.M (Cardiology)</p>
                      <p className="mb-4">Gold Medal in M.D (Internal Medicine)</p>
                      <p className="mb-4">
                        Best Case presentation in 6th Mid-term U.P Interventional
                        meet on 13/09/2015 organized at Lucknow by U.P Chapter,
                        Cardiology Society of India.
                      </p>
                      <p className="mb-4">
                        Achieved highest marks in D.M Cardiology in King George`s
                        Medical University, Lucknow, India
                      </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Achievements</Accordion.Header>
                  <Accordion.Body>
                    <div className="field-body">
                      <p className="mb-4">
                        Among very few cardiologists in India who has been
                        dedicatedly trained in TAVR, TMVR, Mitra-Clip, Tri-Clip
                        and other structural heart interventions
                      </p>
                      <p className="mb-4">
                        Performed TMVR in Ring preceded by Alcohol septal ablation
                        with Sapien 3 Valve – First case in India
                      </p>
                      <p className="mb-4">
                        Performed open Trans atrial TMVR in MAC with
                        balloon-expandable valve (My-Val) - First case in India
                        (July 27, 2022)
                      </p>
                      <p className="mb-4">
                        Performed TAVR on the oldest patient in India (104 years
                        old) and 3rd oldest patient in the world (August 02, 2022)
                      </p>
                      <p className="mb-4">
                        Performed TAVR and Leadless Micra (Dual sensing) in same
                        sitting as a second case in India (February 23, 2022)
                      </p>
                      <p className="mb-4">
                        Achieved highest marks / Gold medal in D.M Cardiology in
                        King George`s Medical University, Lucknow, India
                      </p>
                      <p className="mb-4">
                        Awarded Gold medal by Mahatma Gandhi Memorial Medical
                        College, DAVV University, Indore, India for Best PG in
                        Internal Medicine
                      </p>
                      <p className="mb-4">
                        Best Case presentation in Interventional meet on September
                        13, 2015, by the cardiology society of India
                      </p>
                      <p className="mb-4">
                        Educational Commission for Foreign Medical Graduates
                        (ECFMG) Certified – December 18, 2019
                      </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>Area Of Expertise</Accordion.Header>
                  <Accordion.Body>
                    <div classname="field-body">
                      <p className="mb-4">
                        Transcatheter Aortic Valve Replacement (TAVR/TAVI)-
                        Experience of more than 300 TAVR cases, including Bicuspid
                        valve, Valve in Valve, TAVR in TAVR
                      </p>
                      <p className="mb-4">
                        Transcatheter Edge to Edge repair (TEER) - One of the very
                        few cardiologists in India having expertise in Mitra-Clip,
                        Tri-Clip
                      </p>
                      <p className="mb-4">TMVR, TPVR, TTVR</p>
                      <p className="mb-4">
                        Complex coronary angioplasty including Impella,
                        Rotablation, Orbital atherectomy, LASER, IVL
                      </p>
                      <p className="mb-4">Left Atrial Appendage device closure</p>
                      <p className="mb-4">Paravalvular Leak closure</p>
                      <p className="mb-4">Alcohol Septal ablation</p>
                      <p className="mb-4">PFO/ASD/VSD/PDA/RSOV device closure</p>
                      <p className="mb-4">BMV/BAV/BPV/Coarctoplasty</p>
                      <p className="mb-4">
                        Peripheral intervention including Carotid, Renal, and
                        Ileo-femoral intervention
                      </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>Presentations</Accordion.Header>
                  <Accordion.Body>
                    <div className="field-body">
                      <p className="mb-4">
                        Case presented on “A case of stuck un-deflated coronary
                        balloon” in the “10th India Live Interventional Cardiology
                        Conference” on 2nd March 2019, organized by the
                        Interventional cardiology foundation of India (ICFI)
                      </p>
                      <p className="mb-4">
                        Case presented on “A case of futility…...never give up!! A
                        case of VT storm with rescue PCI of LM bifurcation in
                        ongoing CPR and recurrent DC shock” in the “10th India
                        Live Interventional Cardiology Conference” on 1st March
                        2019, organized by Interventional cardiology foundation of
                        India (ICFI)
                      </p>
                      <p className="mb-4">
                        Challenging case presented on “Maze of Haze – A case of
                        ECMO &amp; IABP assisted Rota PCI of LM bifurcation in
                        ACS” in ‘‘Transcatheter Cardiovascular Therapeutics [TCT]
                        2018 conference’’ held at San Diego, USA from 21st to 25th
                        September 2018, organized by Cardiovascular Research
                        Foundation
                      </p>
                      <p className="mb-4">
                        Case presented on “Valve in valve Transcatheter Mitral
                        Valve Replacement (TMVR)” in the “India Valve 2018
                        Conference” held in New Delhi, India from 31st August to
                        2nd September 2018, organized by Heart Valve Foundation of
                        India
                      </p>
                      <p className="mb-4">
                        Case presented on “Rota-ablation guided complex PCI to LCX
                        and RCA” in 8th India Live Interventional Cardiology
                        conference on 3rd March 2017 organized by Interventional
                        cardiology foundation of India (ICFI)
                      </p>
                      <p className="mb-4">
                        Case presented on “ECMO assisted Rota-ablation PCI of LMCA
                        and RCA” in 8th India Live Interventional Cardiology
                        conference on 2nd March 2017 organized by Interventional
                        cardiology foundation of India (ICFI)
                      </p>
                      <p className="mb-4">
                        Case presented on “Unusual wire entrapment with fracture
                        during PCI “in 6th Midterm U.P Interventional meet on 13th
                        September 2015 organized at Lucknow by U.P chapter,
                        cardiology society of India
                      </p>
                      <p className="mb-4">
                        Case presented on Scleroderma in: “Workshop of
                        Rheumatology”, March 2012 organized by Department of
                        Medicine, MGM Medical College, Indore, India
                      </p>
                      <p className="mb-4">
                        Case presented in 3rd Annual Refresher course in:
                        “Clinical Examination and Interpretation” January 2012
                        organized by Department of Medicine, MGM Medical College,
                        Indore, India
                      </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>
                    Publications & Research & Experience
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="field-body">
                      <p className="mb-4">
                        Paper publication on “ Efficacy and Safety of an Upfront
                        RotaTripsy Strategy in the Treatment of DeNovo and
                        In-stent Restenosis” in J INVSIVE CARDIOL 2023;35(2):
                        E70-E74.Epub 2023 January 6.
                      </p>
                      <p className="mb-4">
                        Paper publication on “Single centre experience of 105
                        minimalistic transfemoral transcatheter aortic valve
                        replacement and its outcome” in Indian Heart Journal
                        Volume 73, Issue 3, May-June 2021
                      </p>
                      <p className="mb-4">
                        Paper publication on “Bifurcation Stenting: Basics and
                        Beyond” in NIC newsletter organized by National
                        interventional council &amp; Cardiology Society of India,
                        held on 05th to 7th April 2019 at Lucknow, India
                      </p>
                      <p className="mb-4">
                        Paper publication on “Biochemical and blood profile
                        amongst hypertensive subjects reporting at a tertiary care
                        hospital” in International Journal of Medical Research
                        Professionals, DOI: 10.21276/ijmrp.2018.4.6.033
                      </p>
                      <p className="mb-4">
                        Worked as Sub Investigator for Clinical Research protocol:
                        A Multicenter, randomized, double-blind,
                        placebo-controlled, 5-arm, parallel-group study to assess
                        Rotigotine transdermal system dose response in subjects
                        with advanced-stage Parkinson`s disease
                      </p>
                      <p className="mb-4">
                        Worked as Sub Investigator for Clinical Research protocol:
                        A Multicenter “IMPROVE BRADY” trial (study)
                      </p>
                      <p className="mb-4">
                        Worked as Sub Investigator for Clinical Research protocol:
                        A Multicenter “IMPROVE SCA” trial (study)
                      </p>
                      <p className="mb-4">
                        Performed Extensive Clinical Research in the field of
                        Systemic Hypertension. The research work was submitted as
                        an MD thesis entitled “Assessment of endothelial
                        dysfunction by Flow Mediated Dilation of the brachial
                        artery in young patients of systemic hypertension” in
                        M.G.M Medical College, DAVV University Indore, India
                      </p>
                      <p className="mb-4">
                        Performed Extensive Imaging and Clinical Research in the
                        field of Pulmonary Arterial Hypertension. The research
                        work was submitted as a D.M thesis entitled “Assessment of
                        short-term effects of phosphodiesterase -5 inhibitors in
                        patients with secondary pulmonary hypertension.” in King
                        George's Medical University, Lucknow, India
                      </p>
                      <p className="mb-4">
                        Abstract publication on “Assessment of short-term effects
                        of sildenafil therapy in patients with secondary pulmonary
                        hypertension” in Indian Heart Journal 67 (2015) S 124
                      </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <Accordion.Header>Teaching Experience</Accordion.Header>
                  <Accordion.Body>
                    <div className="field-body">
                      <span>
                        <strong>
                          Mahatma Gandhi Memorial Medical College, Indore, India
                        </strong>
                      </span>
                      <p className="mb-4">
                        Worked as a postgraduate cum tutor and was actively
                        involved in teaching medical and dental undergraduates
                        from 2009 to 2012
                      </p>
                      <p className="mb-4">
                        Worked as a senior resident cum tutor and was actively
                        involved in teaching postgraduate students of Internal
                        medicine from 2012 to 2013
                      </p>
                      <span>
                        <strong>
                          King George`s Medical University, Lucknow, India
                        </strong>
                      </span>
                      <p className="mb-4">
                        Worked as Cardiology Fellow cum tutor and was actively
                        involved in teaching medical undergraduates and
                        postgraduates from 2013 to 2016
                      </p>
                      <span>
                        <strong>Mount Sinai Hospital, New York, USA</strong>
                      </span>
                      <p className="mb-4">
                        Worked as Interventional cardiology / Structural heart
                        fellow cum tutor and was actively involved in teaching for
                        General Cardiology fellow from 2021 to June 2022
                      </p>
                      <span className>
                        <strong>Eternal Hospital Jaipur, India</strong>
                      </span>
                      <p className="mb-4">
                        Working as teaching faculty for DNB Cardiology fellow
                        since September 2019 to date
                      </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                  <Accordion.Header> Membership</Accordion.Header>
                  <Accordion.Body>
                    <div className="field-body">
                      <p className="mb-4">
                        Fellow American College of Cardiology (FACC / FIT)
                      </p>
                      <p className="mb-4">
                        Lifetime Member of the Cardiology Society of India (CSI)
                        (L-4245)
                      </p>
                      <p className="mb-4">
                        International Associate, Society of Cardiovascular
                        Angiography and Interventions (SCAI)
                      </p>
                      <p className="mb-4">
                        Member of the European Society of Cardiology (ESC)
                        (722180)
                      </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>

          </div>
        </section>

        <HomeVideo />

        {Treatmentdata?.length > 0 ? (
          <section className="section-gap-md treatment">
            <div className="container">
              <div className="section-title section-center">
                <h2>Our Treatments</h2>
                <span className="shape-line">
                  {" "}
                  {/* Fix: Use className instead of className */}
                  <i className="icon-19"></i>
                </span>
                <p>
                  We provide the best cardiac care when you need it. Get
                  personalized and high-quality care for your heart.
                </p>
              </div>
              <div className="row g-3">
                <Swiper
                  spaceBetween={10}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  modules={[Autoplay, Navigation, Pagination]}
                  navigation
                  autoplay={{ delay: 3000 }}
                >
                  {Treatmentdata.map((items, index) => (
                    <SwiperSlide key={index}>
                      <div className="col-lg-3">
                        <div
                          className="treatbox"
                          onClick={() => {
                            navigate(`/treatments/${items?.slug}`);
                          }}
                        >
                          <div className="treatbox-img">
                            <img
                              src={
                                items?.image
                                  ? ImgUrl + "/" + items?.image
                                  : defaultImg
                              }
                              alt={items?.title}
                            ></img>
                          </div>
                          <div className="treatbox-title">
                            {items?.title}
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </section>
        ) : null}

        <section className="section-gap-sm bg-lighten03">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <img src="/images/ci/cardiimg5.jpg" className="img-fluid borderradius05" alt="Top Cardiologist in Jaipur"></img>
              </div>
              <div className="col-lg-6">
                <div className="section-title mb-3">
                  <h3>A Name Eminent in the Realm of Cardiology</h3>
                </div>
                <p><a href="https://drprashantdwivedi.com/" target="_blank">Dr. Prashant Dwivedi</a>
                  , the best Cardiologist in Jaipur, is a distinguished name in
                  the field of Cardiology as he has been known for performing some
                  of the complex heart procedures, including high-risk angioplasty
                  for challenging cases, Rotablation for Calcified Blockages, CTO
                  Angioplasty, Left Main Bifurcation Angioplasty, Impella-assisted
                  Angioplasty, IVL-assisted Angioplasty, and Peripheral
                  Angioplasty.
                </p>
                <p>
                  Dr. Dwivedi has become a prodigy in Cardiology as he is one of
                  the few cardiologists in India who is dedicatedly trained in
                  TAVR/MITRA-Clip/TRI-Clip/TMVR and other Structural Heart
                  Interventions. He possesses the specialization and expertise in
                  performing TAVR and other intricate valve and structural heart
                  interventions such as TMVR (Transcatheter Mitral Valve
                  Replacement), MitraClip procedure for Mitral Regurgitation
                  Repair, TPVR (Transcatheter Pulmonary Valve Replacement), TTVR
                  (Transcatheter Tricuspid Valve Repair), Alcohol Septal Ablation
                  for Hypertrophic Obstructive Cardiomyopathy treatment, Para
                  Valvular Leak Closure procedures, and closure of ASD/VSD/PDA/PFO
                  devices. Moreover, he also specializes in Left Atrial Appendage
                  Closure, among many other specialized procedures.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="section-gap-sm">
          <div className="container">
            <div className="section-title">
              <h3>What makes Dr. Prashant Dwivedi the Top cardiologist in Jaipur?</h3>
              <p>
                As a leading figure in the field of Cardiology, Dr. Dwivedi has
                become a top Cardiologist in Jaipur who specializes in
                Intervention Cardiology. Over the years, he has performed
                several minimally invasive procedures such as Complex&nbsp;
                <a
                  href="https://drprashantdwivedi.com/treatments/complex-coronary-angioplasty"
                  target="_blank"
                  style={{ color: "#c1272d" }}
                >
                  Coronary Angioplasty,
                </a>
                &nbsp; Rotablation, TAVR, Mitra Clip, TMVR (Transcatheter Mitral
                Valve Replacement), TPVR (Transcatheter Pulmonary Valve
                Replacement), TTVR, Pacemaker and ICD Implantation, and more
                that involves treating several heart diseases. What makes Dr.
                Dwivedi a trusted name in the field of Cardiology is because:
              </p>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <h5>An Expert TAVR Specialist:</h5>
                <p>
                  Dr. Prashant's reputation as a renowned Cardiologist specialist
                  in Jaipur is undeniable by the fact that he has an extensive
                  experience with over 450 TAVR cases. His expertise in the field
                  goes beyond&nbsp;
                  <a

                    href="https://drprashantdwivedi.com/treatments/transcatheter-aortic-valve-replacement"
                    target="_blank"
                    style={{ color: "#c1272d" }}
                  >
                    TAVR&nbsp;
                  </a>
                  as he has also performed several minimally invasive procedures
                  encompassing complex cases such as Bicuspid Valve, Valve in
                  Valve, and TAVR in TAVR, TMVR and more.
                </p>
                <h5>A Strong Educational Background:</h5>
                <p>
                  Dr. Dwivedi is considered at the forefront of notable
                  advancements in minimally invasive procedures in the domain of
                  Cardiology because of his strong educational background. His
                  credibility as a top Cardiology Doctor in Jaipur can be seen by
                  his association with King George's Medical University, Lucknow,
                  for D.M. Cardiology and Fellowship for Interventional Cardiology
                  and Structural Heart Disease at Mount Sinai Hospital New York,
                  USA. With several academic research papers and presentations, he
                  is committed to the latest developments in the field of
                  Cardiology. He is one of the very few cardiologist in India who
                  has been dedicatedly trained in Complex Coronary Intervention
                  and TAVR / Structural Heart Intervention from USA.
                </p>
                <h5>A Patient-Centric Approach:</h5>
                <p>
                  When it comes to providing exceptional care and treatment to
                  patients, Dr. Dwivedi stands true to his reputation as a trusted
                  Cardiologist Doctor near by providing the patients with the best
                  possible treatment and care. Being a cardiologist, he
                  understands that an operation going through a heart disease can
                  be overwhelmed with the treatment. That is why he ensures that
                  the patients are well-informed about the treatment while
                  maintaining empathy and communicating with care.
                </p>
              </div>
            </div>

          </div>
        </section>

        <section className="section-gap-sm bg-lighten03">
          <div className="container">
            <div className="section-title">
              <h3>Keeping it Beating Strong with Dr. Prashant Dwivedi</h3>
              <p>
                Choosing Dr. Prashant Dwivedi as your healthcare provider offers a range of compelling reasons.
              </p>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <p>
                  Dr. Dwivedi possesses a wealth of experience and expertise in his field, ensuring top-notch care for
                  his patients.  His dedication to staying updated with the latest advancements in medical science
                  guarantees that you receive the most effective and innovative treatments available. He also
                  recommends his patients to undergo &nbsp;<a href="/blogs/cardiovascular-exercise-definition-examples-and-benefit" target="_blank">cardiovascular exercise</a>&nbsp; to ensure that their health remain
                  intact.
                </p>
                <p>
                  Moreover, Dr. Dwivedi is known for his compassionate approach, providing personalized care and
                  attention to each patient's needs. His strong communication skills foster trust and understanding,
                  making him an excellent choice for anyone seeking reliable medical care. He will also suggest the
                  patients with <a href="https://mysterioustrip.com/heart-ticking-exercise/" target="_blank">Top Exercises to Keep Your Heart Healthy </a> for their better overall health. Overall,
                  selecting Dr. Prashant Dwivedi ensures not only expertise but also empathy and support throughout
                  your healthcare journey.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="section-gap-md blog-section blog-section-second">
          <div className="container">
            <div className="section-title section-center">
              <h2 className="tx-white">Why choose Dr. Prashant Dwivedi?</h2>
              <p className="tx-white">With the world suffering from increasing heart diseases, having access to the <strong>Best Cardiologist in Jaipur</strong> is
                bliss. Dr. Prashant Dwivedi, being an expert in the field of cardiology, has become a top name for people seeking apt Cardiac care and treatment. You can interest your heart health in the
                expert hands of Dr. Dwivedi as he offers:</p>
            </div>
            <div className="row g-3">
              <div className="col-lg-4">
                <div className="blog blog-style-1">
                  <div className="thumbnail">
                    <a href="#"><img src="/images/cardiologist1.jpg" alt="Best Cardiologist in Jaipur" /></a>
                  </div>
                  <div className="content-seond">
                    <h5 className="title-seond text-center cardio-heading mb-3">Comprehensive Cardiac Care</h5>
                    <p>With a sound educational background and experience, Dr.Dwivedi is well-versed with the latest technology and equipment that ensures that you will get high-quality care
                      and safety for your heart. With his extensive knowledge in performing minimally invasive complex procedures such as Complex Angioplasty, Rotablation, TAVR, TMVR, Mitra Clip,
                      Paravalvular Leak Closure, and more, you are sure to get the best possible Cardiac Care.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="blog blog-style-1">
                  <div className="thumbnail">
                    <a href="#"><img src="/images/cardiologist2.jpg" alt="best Cardiologist Specialist in Jaipur" /></a>
                  </div>
                  <div className="content-seond">
                    <h5 className="title-seond text-center cardio-heading mb-3">Personalized Treatment</h5>
                    <p>
                      Every patient requires different cardiac care, and Dr.
                      Dwivedi, being the Best Cardiologist in Jaipur,
                      understands it. In his more than a decade-long experience
                      in the field, he has been providing patients with
                      personalized care with a patient-centric approach
                      addressing every concern related to the treatment. He
                      addresses a wide range of heart conditions and offers
                      personalized treatment options to the patients.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="blog blog-style-1">
                  <div className="thumbnail">
                    <a href="#"><img src="/images/cardiologist3.jpg" alt="Top Cardiologist in Jaipur" /></a>
                  </div>
                  <div className="content-seond">
                    <h5 className="title-seond text-center cardio-heading mb-3">A Highly- Acclaimed Cardiologist</h5>
                    <p>
                      Dr. Dwivedi's reputation as the Top Cardiologist in Jaipur
                      extends beyond the city. His expertise can be reckoned by
                      the fact that he has been invited actively to perform such
                      Complex heart procedures at well-recognized and renowned
                      hospitals and institutes all across the country as well as
                      Southeast Asia. These invitations were a testament to
                      office expertise and knowledge with TAVR (Transcatheter
                      Aortic Valve Replacement), TMVR, Mitra Clip Procedure, and
                      Complex Angioplasties.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='section-gap-sm 
       text-center'>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <p>
                  With his hard work and dedication, Dr. Prashant Dwivedi has
                  become a name synonymous with "Trust and Care" in the field
                  of cardiology. He has been continuously working in the field
                  of advancing Cardiac Care with a commitment to learning and
                  innovation, making him the Top Cardiologist in Jaipur.
                </p>
                <p>His quest for knowledge knows no bounds, as he continuously immerses himself in the latest
                  advancements and breakthroughs in the field. Dr. Dwivedi's thirst for innovation fuels his efforts to
                  push the boundaries of conventional treatments, always seeking newer, more effective approaches
                  to cardiac care.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className='section-gap-md' style={{ background: 'rgb(246, 247, 248)' }}>
          <div className='container'>
            <div className="section-title section-center">
              <h2>Frequently Asked Questions</h2>
              <span className="shape-line">
                <i className="icon-19"></i>
              </span>
            </div>

            {Faq?.length > 0 ? <>
              <div className="accordbox">
                <Accordion defaultActiveKey="0" flush>
                  {Faq?.map((items, index) => {
                    return (<>
                      <Accordion.Item eventKey={index.toString()}>
                        <Accordion.Header>{items?.pf_question}</Accordion.Header>
                        <Accordion.Body>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: items?.pf_answer,
                            }}
                          ></p>

                        </Accordion.Body>
                      </Accordion.Item>
                    </>)
                  })}

                </Accordion>
              </div>
            </> : ""}

          </div>
        </section>
        {testimonialdata.length > 0 && (
          <section
            className="section-gap-md"
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title section-center">
                    <h2>Patient Testimonial</h2>
                    <span className="shape-line">
                      <i className="icon-19"></i>
                    </span>
                  </div>
                  <div className="testiomnial">
                    <Swiper
                      spaceBetween={0}
                      slidesPerView={4}
                      onSlideChange={() => console.log("slide change")}
                      onSwiper={(swiper) => console.log(swiper)}
                      autoplay={{
                        delay: 2000,
                        disableOnInteraction: false,
                      }}
                      modules={[Autoplay]}
                      breakpoints={{
                        0: {
                          slidesPerView: 1,
                        },
                        400: {
                          slidesPerView: 1,
                        },
                        639: {
                          slidesPerView: 2,
                        },
                        865: {
                          slidesPerView: 2,
                        },
                        1000: {
                          slidesPerView: 4,
                        },
                        1500: {
                          slidesPerView: 4,
                        },
                        1700: {
                          slidesPerView: 4,
                        },
                      }}
                    >
                      {testimonialdata.map((value, index) => {
                        return (
                          <SwiperSlide key={index}>
                            <div className="testimonial-grid">
                              <div className="thumbnail">
                                <img
                                  src={
                                    value.testimonial_image != null
                                      ? testimonialImagepath +
                                      value.testimonial_image
                                      : "/images/testim.png"
                                  }
                                  alt="Testimonial"
                                />
                                <span className="qoute-icon">
                                  <i className="icon-26"></i>
                                </span>
                              </div>
                              <div className="content">
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: value.testimonial_desc,
                                  }}
                                ></p>
                                <div className="rating-icon">
                                  {generateRatingIcons(value.testimonial_rating)}
                                </div>
                                <h5 className="title">
                                  {value.testimonial_name}
                                </h5>
                                <span className="subtitle">
                                  {value.testimonial_post}
                                </span>
                              </div>
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        <Footer />

      </MobileView>


      <Book_AppointmentModal
        show={bookAppointmentModal}
        handleClose={handleClose}
      />
    </>
  );
}

export default CardiologistJaipur;
