import { useCallback, useEffect, useRef, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { Swiper, SwiperSlide } from "swiper/react";
import { ApiService } from "../../services/apiServices";
import { Autoplay } from "swiper/modules";
import Skeleton from "react-loading-skeleton";

function Cardicintervention() {
  const didMountRef = useRef(true);
  const [cardicinventionData, setcardicinvenstionData] = useState([]);
  const [featuredImagedata, setfeaturedImagedata] = useState([]);
  const [featuredPath, setFeaturedPath] = useState("");


  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (didMountRef.current) {
      getTreatmentsData();
      getCardiacImageData()
    }
    didMountRef.current = false;
  });
  const getTreatmentsData = () => {
    setLoading(true);
    ApiService.Commanfetchrequest("cardiac-interventions").then((response) => {
      if (response.status == "success") {
        setcardicinvenstionData(response.data);
        setLoading(false);
      }
    });
  };
  const getCardiacImageData = () => {
  
    ApiService.Commanfetchrequest("pages-featured-image").then((response) => {
      if (response.status == "success") {
        setfeaturedImagedata(response.data)
        setFeaturedPath(response.featured_image_path)
      }
    });
  };
  const sliderRef = useRef(null);
  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <>
      <BrowserView>
        {loading == true ? (
          <>
            <section className="section-gap-md bg-lighten03">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div className="section-title">
                      <h2>
                        <Skeleton width={"80%"} height={40} />
                      </h2>
                      <p>
                        <Skeleton width={"100%"} height={70} />
                      </p>
                    </div>

                    <a>
                      <Skeleton width={"100%"} height={23} />
                    </a>
                    <a>
                      <Skeleton width={"100%"} height={23} />
                    </a>
                    <a>
                      <Skeleton width={"100%"} height={23} />
                    </a>
                  </div>
                  <div className="col-lg-6">
                    <div className="crdicslider">
                      <Swiper
                        spaceBetween={0}
                        slidesPerView={1}
                        ref={sliderRef}
                        autoplay={{
                          delay: 2500,
                          disableOnInteraction: false,
                        }}
                        modules={[Autoplay]}
                      >
                        <Skeleton width={"100%"} height={340} />
                      </Swiper>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="video-area-1">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-9">
                    <div className="video-gallery">
                      <Skeleton width={"100%"} height={400} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="counterup-area-5 section-gap-md">
              <div className="container">
                <div className="row">
                  <div className="col-lg-3 col-sm-6">
                    <Skeleton width={"100%"} height={130} />
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <Skeleton width={"100%"} height={130} />
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <Skeleton width={"100%"} height={130} />
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <Skeleton width={"100%"} height={130} />
                  </div>
                </div>
              </div>
            </div>
 
          </>
        ) : (
          <>
            <section className="section-gap-md bg-lighten03">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div className="section-title">
                      <h2>Cardiac Interventions</h2>
                      <span className="shape-line">
                        <i className="icon-19"></i>
                      </span>

                    </div>
                    <ul className="features-list">
                      {cardicinventionData.map((value, index) => (
                        <li key={index}>
                          <a href={`/cardiac/${value.page_url}`}>
                            {value.page_name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="col-lg-6">
                    <div className="crdicslider">
                      <Swiper
                        spaceBetween={0}
                        slidesPerView={1}
                        ref={sliderRef}
                        autoplay={{
                          delay: 2500,
                          disableOnInteraction: false,
                        }}
                        modules={[Autoplay]}
                      >
                        {featuredImagedata.map((value, index) => {
                      return (
                        <SwiperSlide>
                          <img
                            src={featuredPath + value}
                            style={{ width: "100%" }}
                          />
                        </SwiperSlide>
             );
            })}
                      </Swiper>
                      <div className="swiper-buttons__box">
                        <div
                          className="swiper-button swiper-button-prev"
                          style={{ alignItems: "center" }}
                          onClick={handlePrev}
                        >
                          <span>
                            <svg
                              width="27"
                              height="22"
                              viewBox="0 0 32 28"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 14L26 14"
                                stroke="currentColor"
                                strokeWidth="3"
                                strokeLinecap="square"
                              ></path>
                              <path
                                d="M17.6514 3L29.6514 14L17.6514 25"
                                stroke="currentColor"
                                strokeWidth="3"
                                strokeLinecap="square"
                              ></path>
                            </svg>
                          </span>
                        </div>
                        <div
                          className="swiper-button swiper-button-next"
                          onClick={handleNext}
                          style={{ alignItems: "center" }}
                        >
                          <span>
                            <svg
                              width="27"
                              height="22"
                              viewBox="0 0 32 28"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 14L26 14"
                                stroke="currentColor"
                                strokeWidth="3"
                                strokeLinecap="square"
                              ></path>
                              <path
                                d="M17.6514 3L29.6514 14L17.6514 25"
                                stroke="currentColor"
                                strokeWidth="3"
                                strokeLinecap="square"
                              ></path>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="video-area-1">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-9">
                    <div className="video-gallery">
                      <div className="thumbnail">
                        <img src="/images/ci/youtubeimage1.jpg"></img>
                        <a
                          href="https://youtu.be/FMEbyeoYIMo"
                          className="video-play-btn video-popup-activation"
                        >
                          <i className="icon-18"></i>
                        </a>
                      </div>
                      <ul className="shape-group">
                        <li className="shape-1 scene">
                          <img
                            data-depth="2"
                            class="rotateit"
                            src="/images/shape-37.png"
                            alt="Shape"
                          />
                        </li>
                        <li className="shape-2 scene">
                          <img
                            data-depth="-2"
                            src="/images/shape-04.png"
                            alt="Shape"
                          />
                        </li>
                        <li className="shape-3 scene shape-light">
                          <img
                            data-depth="2"
                            src="/images/shape-14.png"
                            alt="Shape"
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="counterup-area-5 section-gap-md">
              <div className="container">
                <div className="row">
                  <div className="col-lg-3 col-sm-6">
                    <div className="edu-counterup counterup-style-5 primary-color">
                      <h2 className="counter-item count-number">450+ </h2>
                      <h6 class="title">TAVR Still Counting</h6>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <div className="edu-counterup counterup-style-5 secondary-color">
                      <h2 className="counter-item count-number">10+</h2>
                      <h6 class="title">Experience</h6>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <div className="edu-counterup counterup-style-5 extra02-color">
                      <h2 className="counter-item count-number">1000+</h2>
                      <h6 class="title">
                        Rotablation / IVL / Orbital Atherectomy
                      </h6>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <div className="edu-counterup counterup-style-5 extra05-color">
                      <h2 className="counter-item count-number"> 50+</h2>
                      <h6 class="title">
                        Impella assisted complex angioplasty
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </BrowserView>
      <MobileView>
         {loading == true ? (
          <>
           {/* Skeleton Start */}
           <section className="section-gap-md bg-lighten03">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div className="section-title">
                      <h2>
                        <Skeleton width={"80%"} height={40} />
                      </h2>
                      <p>
                        <Skeleton width={"100%"} height={70} />
                      </p>
                    </div>

                    <a>
                      <Skeleton width={"100%"} height={23} />
                    </a>
                    <a>
                      <Skeleton width={"100%"} height={23} />
                    </a>
                    <a>
                      <Skeleton width={"100%"} height={23} />
                    </a>
                  </div>
                  <div className="col-lg-6">
                    <div className="crdicslider">
                      <Swiper
                        spaceBetween={0}
                        slidesPerView={1}
                        ref={sliderRef}
                        autoplay={{
                          delay: 2500,
                          disableOnInteraction: false,
                        }}
                        modules={[Autoplay]}
                      >
                        <Skeleton width={"100%"} height={340} />
                      </Swiper>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="video-area-1">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-9">
                    <div className="video-gallery">
                      <Skeleton width={"100%"} height={400} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="counterup-area-5 section-gap-md">
              <div className="container">
                <div className="row">
                  <div className="col-lg-3 col-sm-6">
                    <Skeleton width={"100%"} height={130} />
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <Skeleton width={"100%"} height={130} />
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <Skeleton width={"100%"} height={130} />
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <Skeleton width={"100%"} height={130} />
                  </div>
                </div>
              </div>
            </div>
          </>
          ):(
            <>
               <section className="section-gap-md bg-lighten03">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="section-title text-center">
                  <h2>Cardiac Interventions</h2>
                  <span className="shape-line">
                    <i className="icon-19"></i>
                  </span>

                </div>
                <ul className="features-list">
                  {cardicinventionData.map((value, index) => (
                    <li key={index}>
                      <a href={`/cardiac/${value.page_url}`}>
                        {value.page_name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-lg-6">
                <div className="crdicslider">
                  <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    ref={sliderRef}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    modules={[Autoplay]}
                  >
                    <SwiperSlide>
                      <img
                        src="/images/ci/cardiimg1.jpg"
                        style={{ width: "100%" }}
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        src="/images/ci/cardiimg2.jpg"
                        style={{ width: "100%" }}
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        src="/images/ci/cardiimg3.jpg"
                        style={{ width: "100%" }}
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        src="/images/ci/cardiimg4.jpg"
                        style={{ width: "100%" }}
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        src="/images/ci/cardiimg5.jpg"
                        style={{ width: "100%" }}
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        src="/images/ci/cardiimg6.jpg"
                        style={{ width: "100%" }}
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        src="/images/ci/cardiimg7.jpg"
                        style={{ width: "100%" }}
                      />
                    </SwiperSlide>
                  </Swiper>
                  <div className="swiper-buttons__box">
                    <div
                      className="swiper-button swiper-button-prev"
                      style={{ alignItems: "center" }}
                      onClick={handlePrev}
                    >
                      <span>
                        <svg
                          width="27"
                          height="22"
                          viewBox="0 0 32 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2 14L26 14"
                            stroke="currentColor"
                            strokeWidth="3"
                            strokeLinecap="square"
                          ></path>
                          <path
                            d="M17.6514 3L29.6514 14L17.6514 25"
                            stroke="currentColor"
                            strokeWidth="3"
                            strokeLinecap="square"
                          ></path>
                        </svg>
                      </span>
                    </div>
                    <div
                      className="swiper-button swiper-button-next"
                      onClick={handleNext}
                      style={{ alignItems: "center" }}
                    >
                      <span>
                        <svg
                          width="27"
                          height="22"
                          viewBox="0 0 32 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2 14L26 14"
                            stroke="currentColor"
                            strokeWidth="3"
                            strokeLinecap="square"
                          ></path>
                          <path
                            d="M17.6514 3L29.6514 14L17.6514 25"
                            stroke="currentColor"
                            strokeWidth="3"
                            strokeLinecap="square"
                          ></path>
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="video-area-1">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-9">
                <div className="video-gallery">
                  <div className="thumbnail">
                    <img src="/images/ci/youtubeimage1.jpg"></img>
                    <a
                      href="https://youtu.be/FMEbyeoYIMo"
                      className="video-play-btn video-popup-activation"
                    >
                      <i className="icon-18"></i>
                    </a>
                  </div>
                  <ul className="shape-group">
                    <li className="shape-1 scene">
                      <img
                        data-depth="2"
                        class="rotateit"
                        src="/images/shape-37.png"
                        alt="Shape"
                      />
                    </li>
                    <li className="shape-2 scene">
                      <img
                        data-depth="-2"
                        src="/images/shape-04.png"
                        alt="Shape"
                      />
                    </li>
                    <li className="shape-3 scene shape-light">
                      <img
                        data-depth="2"
                        src="/images/shape-14.png"
                        alt="Shape"
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="counterup-area-5 section-gap-md">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <div className="edu-counterup counterup-style-5 primary-color mt-10">
                  <h2 className="counter-item count-number">450+ </h2>
                  <h6 class="title">TAVR Still Counting</h6>
                </div>
              </div>
              <div className="col-lg-3 mt-3">
                <div className="edu-counterup counterup-style-5 secondary-color mt-10">
                  <h2 className="counter-item count-number">10+</h2>
                  <h6 class="title">Experience</h6>
                </div>
              </div>
              <div className="col-lg-3 mt-3">
                <div className="edu-counterup counterup-style-5 extra02-color mt-10">
                  <h2 className="counter-item count-number">1000+</h2>
                  <h6 class="title">Rotablation / IVL / Orbital Atherectomy</h6>
                </div>
              </div>
              <div className="col-lg-3 mt-3">
                <div className="edu-counterup counterup-style-5 extra05-color mt-10">
                  <h2 className="counter-item count-number"> 50+</h2>
                  <h6 class="title">Impella assisted complex angioplasty</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
            </>
         )}
     
      </MobileView>
    </>
  );
}

export default Cardicintervention;
