import React, { useEffect, useRef, useState } from "react"
import Header from "../../components/Header"
import Footer from "../../components/Footer"
import { ApiService } from "../../components/services/apiServices"
import { Accordion } from "react-bootstrap";
import SuccessStories from "../../components/Elements/sections/success_stories";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import HomeVideo from "../../components/Elements/sections/home_video";
import Book_AppointmentModal from "../../components/Elements/Modals/book_appointment";
import constants from "../../components/services/constants";
import { Helmet } from "react-helmet";
function TAVRExpertJaipur() {

    const didMountRef = useRef(true)
    const [pageContent, setPageContent] = useState({})
    const [headerImageUrl, setHeaderImageUrl] = useState("")
    const [testimonialdata, settestimonialdata] = useState([]);
    const [testimonialImagepath, setTestimonialImagepath] = useState("");
    const [Faq, setfaq] = useState([])
    const [bookAppointmentModal, setBookAppointmentModal] = useState(false);
    const handleClose = () => {
        setBookAppointmentModal(false);
    };
    useEffect(() => {
        if (didMountRef.current) {
            getPagesData()
        }
        didMountRef.current = false
    })

    const getPagesData = () => {
        const dataString = {
            slug: "treatments/tavrtavi-expert-in-jaipur-rajasthan"
        }
        ApiService.Commanpostrequest("pages-content", dataString).then((res) => {
            if (res.status === "success") {
                setPageContent(res.data)
                setHeaderImageUrl(res.header_image_path)
                setfaq(res.pageFaqData)
                settestimonialdata(res?.testimonialData);
                setTestimonialImagepath(res?.testimonial_image_url);
            }
        })
    }

    const generateRatingIcons = (rating) => {
        const icons = [];
        for (let i = 0; i < rating; i++) {
            icons.push(<i key={i} className="icon-23"></i>);
        }
        return icons;
    };

    return (

        <>
            <Helmet>
                <title>{pageContent.page_meta_title}</title>
                <meta name="description" itemprop="description" content={pageContent.page_meta_desc != null ? pageContent.page_meta_desc : "Dr Prashant Dwivedi"} />
                {pageContent.page_meta_keyword != null ? <meta name="keywords" content={pageContent.page_meta_keyword} /> : "Dr Prashant Dwivedi"}
                <link rel="canonical" href={window.location.href} />
                <meta property="og:title" content={pageContent.page_meta_title} />
                <meta name="twitter:url" content={window.location.href} />
                <meta property="og:image" content={pageContent.page_header_image ? headerImageUrl + pageContent.page_header_image : constants.META_DEFAULT_IMAGE} />
                <meta property="og:url" content={window.location.href} />
                {pageContent.page_meta_desc != null ? <meta property="og:description" content={pageContent.page_meta_desc} /> : "Dr Prashant Dwivedi"}
                <meta name="twitter:title" content={pageContent.page_meta_title} />
                {pageContent.page_meta_desc != null ? <meta name="twitter:description" content={pageContent.page_meta_desc} /> : "Dr Prashant Dwivedi"}
                <meta property="twitter:image" content={pageContent.page_header_image ? headerImageUrl + pageContent.page_header_image : constants.META_DEFAULT_IMAGE} />
            </Helmet>
            <Header />
            <div className="subheader" style={{ backgroundImage: `url(${headerImageUrl + pageContent.page_header_image})` }}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1>{pageContent.page_name}</h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a href="/">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        {pageContent.page_name}
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            <section className="section-gap-sm">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7">
                        <h2>Dr. Prashant Dwivedi : Leading TAVR Expert In Jaipur</h2>

                            <p>As a leading figure in the healthcare industry, Dr Prashant Dwivedi has transformed the lives of many with his exceptional skills and experience as a TAVR expert in Jaipur. Dr Dwivedi Has more than 15 years of experience as an Interventional Cardiologist
                                and has excelled with his skills in a wide range of cardiology areas and specialisation in procedures such as Complex Coronary Angioplasty, TAVI/TAVR, MITRA-CLIP, Complex Coronary Angioplasty, TRI-CLIP, PFO/ASD/VSD/PDO, and other methods. He holds a reputation as a renowned Cardiologist with extensive experience of over 450 TAVR cases, which makes him undeniably the best in the domain.</p>
                            <p> His expertise is not just limited to TAVR as he has also performed numerous invasive procedures that include Valve in Valve, Bicuspid, TMVR, and other methods. When it comes to providing an exceptional and comprehensive range of Cardiac care and treatment, Dr. Prashant Dwivedi is a name that is reckoned as the Best doctor for TAVR / TAVI in the medical field. With a combination of worl className facilities, advanced technology, and unfathomable knowledge, Dr. Dwivedi, a well known TAVR Expert in Jaipur, has been  providing optimal care and treatment along with advanced Transcatheter Aortic Valve Replacements(TAVR).
                                Let us understand more about the TAVR Procedure here:</p>
                            <div className="mt-4" style={{ display: "flex" }}>
                                <a className="edu-btn btn-medium me-3" href="/about" style={{ width: "220px" }}>Know More <i className="icon-4"></i></a>
                                <a className="edu-btn btn-medium" href="javascript:void(0)" onClick={() => setBookAppointmentModal(true)} >Book Appointment <i className="icon-4"></i></a>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <img
                                src="/images/cardiologist.png"
                                alt="TAVR Expert in JAIPUR"
                                style={{ width: "100%" }}
                            />
                        </div>
                    </div>
                </div>
            </section>

            <section className='section-gap-sm bg-lighten03'>
                <div className='container'>
                    <div className="accordbox">
                        <Accordion defaultActiveKey="0" flush>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Academic Record</Accordion.Header>
                                <Accordion.Body>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="mb-4">
                                                <b>
                                                    Fellowship Structural Heart Intervention, 2021-2022
                                                </b>{" "}
                                                <br />
                                                Mount Sinai Hospital, New York, USA
                                            </p>
                                        </div>
                                        <div className="col-md-6">
                                            <p className="mb-4">
                                                <b>Fellowship Interventional Cardiology, 2020-2021</b>{" "}
                                                <br />
                                                Mount Sinai Hospital, New York, USA
                                            </p>
                                        </div>
                                        <div className="col-md-6">
                                            <p className="mb-4">
                                                <b>Fellowship DM Cardiology - 2013 – 2016</b> <br />
                                                King George Medical University, Lucknow, India
                                            </p>
                                        </div>
                                        <div className="col-md-6">
                                            <p className="mb-4">
                                                <b>Senior Residency, Internal Medicine - 2012- 2013</b>{" "}
                                                <br />
                                                M.G.M Medical College &amp; DAVV, Indore, India
                                            </p>
                                        </div>
                                        <div className="col-md-6">
                                            <p className="mb-4">
                                                <b>MD (Residency), Internal Medicine - 2009- 2012</b>{" "}
                                                <br />
                                                M.G.M Medical College &amp; DAVV, Indore, India
                                            </p>
                                        </div>
                                        <div className="col-md-6">
                                            <p className="mb-4">
                                                <b>Internship – 2008 - 2009</b> <br />
                                                M.G.M Medical College &amp; DAVV, Indore, India
                                            </p>
                                        </div>
                                        <div className="col-md-6">
                                            <p className="mb-4">
                                                <b>MBBS - 2003 - 2008</b> <br />
                                                M.G.M Medical College &amp; DAVV, Indore, India
                                            </p>
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Work Experience</Accordion.Header>
                                <Accordion.Body>
                                    <div className="field-body">
                                        <p className="mb-4">
                                            <b>
                                                In charge &amp; Head, TAVR and Structural Heart Program,
                                                Consultant Interventional Cardiology, since December
                                                2022
                                            </b>{" "}
                                            <br />
                                            Eternal Hospital and Research Institute, Jaipur, India
                                        </p>
                                        <p className="mb-4">
                                            <b>
                                                Associate Director TAVR and Structural Heart Program,
                                                Consultant Interventional Cardiology, since July 2022
                                            </b>{" "}
                                            <br />
                                            Eternal Hospital and Research Institute, Jaipur, India
                                        </p>
                                        <p className="mb-4">
                                            <b>Consultant, Interventional Cardiology, 2016 – 2021</b>{" "}
                                            <br />
                                            Eternal Hospital and Research Institute, Jaipur, India
                                        </p>
                                        <p className="mb-4">
                                            <b>
                                                Proctor for TAVR across India &amp; International (MyVal
                                                &amp; Medtronic Evolut){" "}
                                            </b>{" "}
                                            <br />
                                            Eternal Hospital and Research Institute, Jaipur, India
                                        </p>
                                        <p className="mb-4">
                                            <b>
                                                Proctor for Rotational Atherectomy across India (Boston
                                                Scientific)
                                            </b>{" "}
                                            <br />
                                            Eternal Hospital and Research Institute, Jaipur, India
                                        </p>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Awards</Accordion.Header>
                                <Accordion.Body>
                                    <div className="field-body">
                                        <p className="mb-4">Gold Medal in D.M (Cardiology)</p>
                                        <p className="mb-4">Gold Medal in M.D (Internal Medicine)</p>
                                        <p className="mb-4">
                                            Best Case presentation in 6th Mid-term U.P Interventional
                                            meet on 13/09/2015 organized at Lucknow by U.P Chapter,
                                            Cardiology Society of India.
                                        </p>
                                        <p className="mb-4">
                                            Achieved highest marks in D.M Cardiology in King George`s
                                            Medical University, Lucknow, India
                                        </p>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Achievements</Accordion.Header>
                                <Accordion.Body>
                                    <div className="field-body">
                                        <p className="mb-4">
                                            Among very few cardiologists in India who has been
                                            dedicatedly trained in TAVR, TMVR, Mitra-Clip, Tri-Clip
                                            and other structural heart interventions
                                        </p>
                                        <p className="mb-4">
                                            Performed TMVR in Ring preceded by Alcohol septal ablation
                                            with Sapien 3 Valve – First case in India
                                        </p>
                                        <p className="mb-4">
                                            Performed open Trans atrial TMVR in MAC with
                                            balloon-expandable valve (My-Val) - First case in India
                                            (July 27, 2022)
                                        </p>
                                        <p className="mb-4">
                                            Performed TAVR on the oldest patient in India (104 years
                                            old) and 3rd oldest patient in the world (August 02, 2022)
                                        </p>
                                        <p className="mb-4">
                                            Performed TAVR and Leadless Micra (Dual sensing) in same
                                            sitting as a second case in India (February 23, 2022)
                                        </p>
                                        <p className="mb-4">
                                            Achieved highest marks / Gold medal in D.M Cardiology in
                                            King George`s Medical University, Lucknow, India
                                        </p>
                                        <p className="mb-4">
                                            Awarded Gold medal by Mahatma Gandhi Memorial Medical
                                            College, DAVV University, Indore, India for Best PG in
                                            Internal Medicine
                                        </p>
                                        <p className="mb-4">
                                            Best Case presentation in Interventional meet on September
                                            13, 2015, by the cardiology society of India
                                        </p>
                                        <p className="mb-4">
                                            Educational Commission for Foreign Medical Graduates
                                            (ECFMG) Certified – December 18, 2019
                                        </p>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>Area Of Expertise</Accordion.Header>
                                <Accordion.Body>
                                    <div classname="field-body">
                                        <p className="mb-4">
                                            Transcatheter Aortic Valve Replacement (TAVR/TAVI)-
                                            Experience of more than 300 TAVR cases, including Bicuspid
                                            valve, Valve in Valve, TAVR in TAVR
                                        </p>
                                        <p className="mb-4">
                                            Transcatheter Edge to Edge repair (TEER) - One of the very
                                            few cardiologists in India having expertise in Mitra-Clip,
                                            Tri-Clip
                                        </p>
                                        <p className="mb-4">TMVR, TPVR, TTVR</p>
                                        <p className="mb-4">
                                            Complex coronary angioplasty including Impella,
                                            Rotablation, Orbital atherectomy, LASER, IVL
                                        </p>
                                        <p className="mb-4">Left Atrial Appendage device closure</p>
                                        <p className="mb-4">Paravalvular Leak closure</p>
                                        <p className="mb-4">Alcohol Septal ablation</p>
                                        <p className="mb-4">PFO/ASD/VSD/PDA/RSOV device closure</p>
                                        <p className="mb-4">BMV/BAV/BPV/Coarctoplasty</p>
                                        <p className="mb-4">
                                            Peripheral intervention including Carotid, Renal, and
                                            Ileo-femoral intervention
                                        </p>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>Presentations</Accordion.Header>
                                <Accordion.Body>
                                    <div className="field-body">
                                        <p className="mb-4">
                                            Case presented on “A case of stuck un-deflated coronary
                                            balloon” in the “10th India Live Interventional Cardiology
                                            Conference” on 2nd March 2019, organized by the
                                            Interventional cardiology foundation of India (ICFI)
                                        </p>
                                        <p className="mb-4">
                                            Case presented on “A case of futility…...never give up!! A
                                            case of VT storm with rescue PCI of LM bifurcation in
                                            ongoing CPR and recurrent DC shock” in the “10th India
                                            Live Interventional Cardiology Conference” on 1st March
                                            2019, organized by Interventional cardiology foundation of
                                            India (ICFI)
                                        </p>
                                        <p className="mb-4">
                                            Challenging case presented on “Maze of Haze – A case of
                                            ECMO &amp; IABP assisted Rota PCI of LM bifurcation in
                                            ACS” in ‘‘Transcatheter Cardiovascular Therapeutics [TCT]
                                            2018 conference’’ held at San Diego, USA from 21st to 25th
                                            September 2018, organized by Cardiovascular Research
                                            Foundation
                                        </p>
                                        <p className="mb-4">
                                            Case presented on “Valve in valve Transcatheter Mitral
                                            Valve Replacement (TMVR)” in the “India Valve 2018
                                            Conference” held in New Delhi, India from 31st August to
                                            2nd September 2018, organized by Heart Valve Foundation of
                                            India
                                        </p>
                                        <p className="mb-4">
                                            Case presented on “Rota-ablation guided complex PCI to LCX
                                            and RCA” in 8th India Live Interventional Cardiology
                                            conference on 3rd March 2017 organized by Interventional
                                            cardiology foundation of India (ICFI)
                                        </p>
                                        <p className="mb-4">
                                            Case presented on “ECMO assisted Rota-ablation PCI of LMCA
                                            and RCA” in 8th India Live Interventional Cardiology
                                            conference on 2nd March 2017 organized by Interventional
                                            cardiology foundation of India (ICFI)
                                        </p>
                                        <p className="mb-4">
                                            Case presented on “Unusual wire entrapment with fracture
                                            during PCI “in 6th Midterm U.P Interventional meet on 13th
                                            September 2015 organized at Lucknow by U.P chapter,
                                            cardiology society of India
                                        </p>
                                        <p className="mb-4">
                                            Case presented on Scleroderma in: “Workshop of
                                            Rheumatology”, March 2012 organized by Department of
                                            Medicine, MGM Medical College, Indore, India
                                        </p>
                                        <p className="mb-4">
                                            Case presented in 3rd Annual Refresher course in:
                                            “Clinical Examination and Interpretation” January 2012
                                            organized by Department of Medicine, MGM Medical College,
                                            Indore, India
                                        </p>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="6">
                                <Accordion.Header>
                                    Publications & Research & Experience
                                </Accordion.Header>
                                <Accordion.Body>
                                    <div className="field-body">
                                        <p className="mb-4">
                                            Paper publication on “ Efficacy and Safety of an Upfront
                                            RotaTripsy Strategy in the Treatment of DeNovo and
                                            In-stent Restenosis” in J INVSIVE CARDIOL 2023;35(2):
                                            E70-E74.Epub 2023 January 6.
                                        </p>
                                        <p className="mb-4">
                                            Paper publication on “Single centre experience of 105
                                            minimalistic transfemoral transcatheter aortic valve
                                            replacement and its outcome” in Indian Heart Journal
                                            Volume 73, Issue 3, May-June 2021
                                        </p>
                                        <p className="mb-4">
                                            Paper publication on “Bifurcation Stenting: Basics and
                                            Beyond” in NIC newsletter organized by National
                                            interventional council &amp; Cardiology Society of India,
                                            held on 05th to 7th April 2019 at Lucknow, India
                                        </p>
                                        <p className="mb-4">
                                            Paper publication on “Biochemical and blood profile
                                            amongst hypertensive subjects reporting at a tertiary care
                                            hospital” in International Journal of Medical Research
                                            Professionals, DOI: 10.21276/ijmrp.2018.4.6.033
                                        </p>
                                        <p className="mb-4">
                                            Worked as Sub Investigator for Clinical Research protocol:
                                            A Multicenter, randomized, double-blind,
                                            placebo-controlled, 5-arm, parallel-group study to assess
                                            Rotigotine transdermal system dose response in subjects
                                            with advanced-stage Parkinson`s disease
                                        </p>
                                        <p className="mb-4">
                                            Worked as Sub Investigator for Clinical Research protocol:
                                            A Multicenter “IMPROVE BRADY” trial (study)
                                        </p>
                                        <p className="mb-4">
                                            Worked as Sub Investigator for Clinical Research protocol:
                                            A Multicenter “IMPROVE SCA” trial (study)
                                        </p>
                                        <p className="mb-4">
                                            Performed Extensive Clinical Research in the field of
                                            Systemic Hypertension. The research work was submitted as
                                            an MD thesis entitled “Assessment of endothelial
                                            dysfunction by Flow Mediated Dilation of the brachial
                                            artery in young patients of systemic hypertension” in
                                            M.G.M Medical College, DAVV University Indore, India
                                        </p>
                                        <p className="mb-4">
                                            Performed Extensive Imaging and Clinical Research in the
                                            field of Pulmonary Arterial Hypertension. The research
                                            work was submitted as a D.M thesis entitled “Assessment of
                                            short-term effects of phosphodiesterase -5 inhibitors in
                                            patients with secondary pulmonary hypertension.” in King
                                            George's Medical University, Lucknow, India
                                        </p>
                                        <p className="mb-4">
                                            Abstract publication on “Assessment of short-term effects
                                            of sildenafil therapy in patients with secondary pulmonary
                                            hypertension” in Indian Heart Journal 67 (2015) S 124
                                        </p>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="7">
                                <Accordion.Header>Teaching Experience</Accordion.Header>
                                <Accordion.Body>
                                    <div className="field-body">
                                        <span>
                                            <strong>
                                                Mahatma Gandhi Memorial Medical College, Indore, India
                                            </strong>
                                        </span>
                                        <p className="mb-4">
                                            Worked as a postgraduate cum tutor and was actively
                                            involved in teaching medical and dental undergraduates
                                            from 2009 to 2012
                                        </p>
                                        <p className="mb-4">
                                            Worked as a senior resident cum tutor and was actively
                                            involved in teaching postgraduate students of Internal
                                            medicine from 2012 to 2013
                                        </p>
                                        <span>
                                            <strong>
                                                King George`s Medical University, Lucknow, India
                                            </strong>
                                        </span>
                                        <p className="mb-4">
                                            Worked as Cardiology Fellow cum tutor and was actively
                                            involved in teaching medical undergraduates and
                                            postgraduates from 2013 to 2016
                                        </p>
                                        <span>
                                            <strong>Mount Sinai Hospital, New York, USA</strong>
                                        </span>
                                        <p className="mb-4">
                                            Worked as Interventional cardiology / Structural heart
                                            fellow cum tutor and was actively involved in teaching for
                                            General Cardiology fellow from 2021 to June 2022
                                        </p>
                                        <span className>
                                            <strong>Eternal Hospital Jaipur, India</strong>
                                        </span>
                                        <p className="mb-4">
                                            Working as teaching faculty for DNB Cardiology fellow
                                            since September 2019 to date
                                        </p>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="8">
                                <Accordion.Header> Membership</Accordion.Header>
                                <Accordion.Body>
                                    <div className="field-body">
                                        <p className="mb-4">
                                            Fellow American College of Cardiology (FACC / FIT)
                                        </p>
                                        <p className="mb-4">
                                            Lifetime Member of the Cardiology Society of India (CSI)
                                            (L-4245)
                                        </p>
                                        <p className="mb-4">
                                            International Associate, Society of Cardiovascular
                                            Angiography and Interventions (SCAI)
                                        </p>
                                        <p className="mb-4">
                                            Member of the European Society of Cardiology (ESC)
                                            (722180)
                                        </p>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>

                </div>
            </section>

            <section className="section-gap-sm">
                <div className="container">

                    <h2><span style={{ fontSize: "22px" }}>TAVR/TAVI: What is it?</span></h2>

                    <p>TAVR( Transcatheter Aortic Valve Replacements) is a treatment or procedure to treat Aortic Stenosis, which is a condition in which the aortic valve becomes narrow. The symptoms of this condition are shortness of breath, chest pain and or giddiness. Also known as Transcatheter Aortic Valve Implantation( TAVI), TAVR is the procedure used for replacing a diseased aortic valve with a new aortic valve made with animal tissue. A qualified and trained TAVR / TAVI specialist in Jaipur, Rajasthan can perform this minimally invasive procedure by performing a small cut on the skin.</p>

                    <p>The Aortic Valve is one of the four valves that are responsible for controlling the flow of blood in the heart; the aorta specifically helps to run the blood from the heart to the rest of the body. The narrowing of the aortic valve blocks the flow of blood in the body, resulting in forcing the heart to work harder.</p>

                    <h2><span style={{ fontSize: "22px" }}>TAVR/ TAVI: How does it work?</span></h2>

                    <p>TAVR/TAVI is a procedure usually performed by the Best Doctor for TAVR in Rajasthan and is similar to the procedure of placing a stent in an artery. The objective or approach of the procedure is to deliver a fully collapsible valve to the site of the valve with the help of a Catheter. When the new valve is expanded, it pushes out the leaflets of the old valve, and the tissue that is used in the new valve controls the job of regulating the blood in the heart and the body.</p>

                    <h2><span style={{ fontSize: "22px" }}>The overall Procedure of TAVI/TAVR:</span></h2>

                    <p>When it comes to valve replacement, traditionally, it is done with an open-heart procedure with a " sternotomy" procedure in which the chest is opened or separated surgically. The TAVR/ TAVI procedure done by the Best Cardiologist in Jaipur doesn't require opening the chest, and the procedure can be done with a small incision that leaves all the chest bones in place. It is a minimally invasive procedure. The procedure is performed without any significant risk and is also a well-known and beneficial treatment, among other options. One of the most common ways of performing the procedure is by using the femoral artery, which is a blood vessel that is present in the groin or thigh.
                        The process is done by guiding a tube with the loaded new valve via the femoral artery to the heart.</p>

                </div>
            </section>

            <section className="section-gap-sm bg-lighten03">
                <div className="container">
                    <div className="section-title">
                        <h3>Advantages of TAVR/ TAVI over other Procedures:</h3>
                        <p>
                            <a href="https://drprashantdwivedi.com/cardiac/transcatheter-aortic-valve-replacement-tavr-tavi"><span style={{ color: "#2980b9" }}></span>Transcatheter Aortic Valve Implantation</a> is a procedure that offers every advantage in comparison to conventional open heart surgery. Some of the benefits or advantages of this procedure that have made it a preferred option for patients are:
                        </p>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <h5>Minimally Invasive:</h5>
                            <p>
                                TAVR/TAVI is a procedure that requires cutting a small incision on the skin through which the fully collapsible valve is delivered to the site. The process eliminates the need for large chest incisions and is minimally invasive, which is quite different from other procedures and helps reduce the trauma to the tissues surrounding the site of the procedure.
                            </p>

                            <h5>Lower Rate of Complications:</h5>
                            <p>
                                With smaller incisions on the skin and reduced trauma in the body, TAVR/TAVI is a procedure that is considered to have a lesser risk of complications such as infection and bleeding.
                            </p>

                            <h5> Duration of Recovery:</h5>

                            <p>In comparison to the conventional surgery method, such as open heart surgery, TAVR/ TAVI doesn't require much recovery time. As the procedure has a minimally invasive nature, the patients heal in a shorter time and return to their daily activities way sooner than traditional surgeries.</p>


                            <h5>Type of Sedatives used:</h5>
                            <p>TAVR/ TAVI is a procedure that can be performed even under local anaesthesia or any kind of mild Sedatives. It doesn't require the use of general anaesthesia, which is considered complicated for certain patients.
                            </p>

                            <h5>A Viable Option:</h5>
                            <p>The treatment of any patient depends upon the condition and complexity that the patient might face during the procedure. TAVR/ TAVI is considered to be a suitable option for patients who are not suitable for open heart surgery or have a higher risk of complication due to their age or the presence of any other medical condition that might become a hindrance.</p>


                            <h5>Less Scarring:</h5>
                            <p>Open heart surgery and other surgeries usually leave large scars on the skin, but TAVR/ TAVI is a minimally invasive procedure that requires only a small cut, which is why it doesn't leave large scars on the skin. The small scars can become less visible with the duration of time.
                            </p>

                            <h5>Returning to Normal Lifestyle:</h5>
                            <p>Patients who undergo the procedure of TAVR/ TAVI experience faster recovery in comparison to open heart surgery, which involves a longer recovery period. Due to the short recovery duration required in this procedure, the patient can return to their normal activities sooner. You can also do some of the most recommended 5 Exercises to keep your heart strong for faster recovery.
                            </p>

                            <h5>Pain Due to the Procedure:
                            </h5>
                            <p>Some surgical procedure, such as open heart surgery, requires cutting larger incisions, which results in patients suffering from discount and pain. TAVR/ TAVI is a procedure with less pain and discomfort after an operation because it involves smaller incisions on the skin.
                            </p>
                            <p>
                                Heart-Lung Bypass: In surgical procedures such as open heart surgery, a hurt-lung bypass machine is required, which mainly increases the risk of complications related to bypass. TAVR/ TAVI procedure, on the other side, doesn't require such a machine, and that is why it reduces the risk of bypass complications.
                            </p>


                            <h5>Valve-in-valve Option:
                            </h5>
                            <p>Patients who have already received the Surgical Aortic Valve Replacement (SAVR) but may require a new valve because of its deteriorating state can opt for the TAVR/ TAVI procedure as a viable option. It's important to note that while TAVI offers numerous benefits, the suitability of the procedure depends on each patient's individual medical condition and overall health. A thorough evaluation by the best cardiologist in Jaipur who is qualified and expert in TAVR is essential to determine the most appropriate treatment approach for each patient.
                            </p>

                            <h5>The risk associated with TAVR:
                            </h5>
                            <p>However, it is to be noted that the TAVR procedure is also not without risks. Some patients have faced complications such as pushing aside the replacement valve to an old valve flap that blocks the blood flow of the heart. This results in coronary artery obstruction, which is a rare and life threatening complication that one might face due to the procedure. It is important to get the procedure done by a TAVR / TAVI Expert in Jaipur, Rajasthan so that any kind of complication can be avoided while performing the procedure.
                            </p>


                        </div>
                    </div>

                </div>
            </section>


            <SuccessStories />

            <section className="section-gap-md blog-section blog-section-second">
                <div className="container">
                    <div className="section-title section-center">
                        <h2 className="tx-white">Dr. Prashant Dwivedi - Best TAVR Doctor in Jaipur, Rajasthan</h2>
                        <p className="tx-white">TAVR/ TAVI is a procedure that requires expertise so that the whole minimally invasive procedure can be done without and
                            complications. A Gold Medalist in D.M( Cardiology), Dr. Prashant Dwivedi has been a well-established name as a Cardiologist and Expert complex Angioplasty, Heart valves -TAVR / TAVI Rajasthan.</p>
                        <p className="tx-white">He is one of the cardiologists who has been dedicatedly trained in TAVR and other advanced complex structural heart procedures from the world-renowned Mount Sinai Hospital, New York, USA. His experience and skill in performing the complex procedure of TAVR can be seen by the fact that Dr. Dwivedi has performed over 450 TAVR ( Transcatheter Aortic Valve Replacement) cases along with several minimally invasive procedures that compasses complex cases such as Valve in Valve, Bicuspid Valve, TMVR, TAVR In TAVR, Mitra Clip Procedure, Complex Angioplasties, Impella and more.The reason why
                            Dr Prashant Dwivedi is considered the TAVR expert in Jaipur is because:</p>
                    </div>
                    <div className="row g-3">
                        <div className="col-lg-4">
                            <div className="blog blog-style-1">
                                <div className="thumbnail">
                                    <a href="#"><img src="/images/cardiologist1.jpg" alt="Best Cardiologist in Jaipur" /></a>
                                </div>
                                <div className="content-seond">
                                    <h5 className="title-seond text-center cardio-heading mb-3">A Strong TAVR Base</h5>
                                    <p>Dr. Dwivedi has a sound educational background and experience along with performing over 450 cases of TAVR under his belt. He has a vast knowledge of performing minimally invasive as well as complex procedures, which makes him the best choice among his peers.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="blog blog-style-1">
                                <div className="thumbnail">
                                    <a href="#"><img src="/images/cardiologist2.jpg" alt="best Cardiologist Specialist in Jaipur" /></a>
                                </div>
                                <div className="content-seond">
                                    <h5 className="title-seond text-center cardio-heading mb-3">Employs Advanced Technology</h5>
                                    <p>
                                        As an expert Cardiologist, Dr Dwivedi aims for precise treatment, and that is why every procedure is performed by following high-tech imaging technology for accurate placement of the valve.
                                        He ensures to eliminate of any risk or complication to enhance the outcome of the procedure.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="blog blog-style-1">
                                <div className="thumbnail">
                                    <a href="#"><img src="/images/cardiologist3.jpg" alt="Top Cardiologist in Jaipur" /></a>
                                </div>
                                <div className="content-seond">
                                    <h5 className="title-seond text-center cardio-heading mb-3">Patient-Centric Approach</h5>
                                    <p>
                                        With a patient-driven approach, Dr Prashant Dwivedi offers the patients a tailor-made treatment and diagnosis as per their unique heart anatomy. He focuses on providing effective treatment for severe aortic stenosis treatment to his patients.
                                    </p>

                                </div>
                            </div>
                        </div>
                        <p className="tx-white mt-5" >
                            Being a reputed name in the domain, Dr. Dwivedi extends his reputation beyond
                            the country as the Best Doctor for TAVR / TAVI in Jaipur as he has been invited actively to perform several Complex
                            heart procedures by reputed and renowned hospitals in India and Southeast Asia. With his hard work and dedication to
                            the patients to provide the best treatment possible with his knowledge and experience,
                            Dr. Dwivedi has been impacting the lives of his patients positively. If you are looking for advanced healthcare and treatment
                            for your cardiac condition, then reach out to <a href="/" style={{ color: "#000" }}>Dr. Prashant Dwivedi</a> for the best treatment and cardiac care.
                        </p>
                    </div>
                </div>
            </section>

            <div className="counterup-area-5 section-gap-md">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-sm-6">
                            <div className="edu-counterup counterup-style-5 primary-color">
                                <h2 className="counter-item count-number">450+</h2>

                                <h6 className="title">TAVR Still Counting</h6>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6">
                            <div className="edu-counterup counterup-style-5 secondary-color">
                                <h2 className="counter-item count-number">10+</h2>

                                <h6 className="title">Experience</h6>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6">
                            <div className="edu-counterup counterup-style-5 extra02-color">
                                <h2 className="counter-item count-number">1000+</h2>

                                <h6 className="title">Rotablation / IVL / Orbital Atherectomy</h6>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6">
                            <div className="edu-counterup counterup-style-5 extra05-color">
                                <h2 className="counter-item count-number">50+</h2>

                                <h6 className="title">Impella assisted complex angioplasty</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <HomeVideo />

            {testimonialdata.length > 0 && (
                <section
                    className="section-gap-md"
                >
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title section-center">
                                    <h2>Patient Testimonial</h2>
                                    <span className="shape-line">
                                        <i className="icon-19"></i>
                                    </span>
                                </div>
                                <div className="testiomnial">
                                    <Swiper
                                        spaceBetween={0}
                                        slidesPerView={4}
                                        onSlideChange={() => console.log("slide change")}
                                        onSwiper={(swiper) => console.log(swiper)}
                                        autoplay={{
                                            delay: 2000,
                                            disableOnInteraction: false,
                                        }}
                                        modules={[Autoplay]}
                                        breakpoints={{
                                            0: {
                                                slidesPerView: 1,
                                            },
                                            400: {
                                                slidesPerView: 1,
                                            },
                                            639: {
                                                slidesPerView: 2,
                                            },
                                            865: {
                                                slidesPerView: 2,
                                            },
                                            1000: {
                                                slidesPerView: 4,
                                            },
                                            1500: {
                                                slidesPerView: 4,
                                            },
                                            1700: {
                                                slidesPerView: 4,
                                            },
                                        }}
                                    >
                                        {testimonialdata.map((value, index) => {
                                            return (
                                                <SwiperSlide key={index}>
                                                    <div className="testimonial-grid">
                                                        <div className="thumbnail">
                                                            <img
                                                                src={
                                                                    value.testimonial_image != null
                                                                        ? testimonialImagepath +
                                                                        value.testimonial_image
                                                                        : "/images/testim.png"
                                                                }
                                                                alt="Testimonial"
                                                            />
                                                            <span className="qoute-icon">
                                                                <i className="icon-26"></i>
                                                            </span>
                                                        </div>
                                                        <div className="content">
                                                            <p
                                                                dangerouslySetInnerHTML={{
                                                                    __html: value.testimonial_desc,
                                                                }}
                                                            ></p>
                                                            <div className="rating-icon">
                                                                {generateRatingIcons(value.testimonial_rating)}
                                                            </div>
                                                            <h5 className="title">
                                                                {value.testimonial_name}
                                                            </h5>
                                                            <span className="subtitle">
                                                                {value.testimonial_post}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            );
                                        })}
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}




            <section className='section-gap-md' style={{ background: 'rgb(246, 247, 248)' }}>
                <div className='container'>
                    <div className="section-title section-center">
                        <h2>Frequently Asked Questions</h2>
                        <span className="shape-line">
                            <i className="icon-19"></i>
                        </span>
                    </div>


                    {Faq?.length > 0 ? <>
                        <div className="accordbox">
                            <Accordion defaultActiveKey="0" flush>
                                {Faq?.map((items, index) => {
                                    return (<>
                                        <Accordion.Item eventKey={index.toString()}>
                                            <Accordion.Header>{items?.pf_question}</Accordion.Header>
                                            <Accordion.Body>
                                                <p
                                                    dangerouslySetInnerHTML={{
                                                        __html: items?.pf_answer,
                                                    }}
                                                ></p>

                                            </Accordion.Body>
                                        </Accordion.Item>


                                    </>)
                                })}

                            </Accordion>
                        </div>
                    </> : ""}

                </div>
            </section>





            <Footer />
            <Book_AppointmentModal
                show={bookAppointmentModal}
                handleClose={handleClose}
            />

        </>

    )




}
export default TAVRExpertJaipur