import { useEffect, useRef, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { Swiper, SwiperSlide } from "swiper/react";
import { ApiService } from "../../services/apiServices";
import Skeleton from "react-loading-skeleton";
import Book_AppointmentModal from "../Modals/book_appointment";
import { Autoplay } from "swiper/modules";

function HomeBanner() {
  const [bannerImg, setbannerImg] = useState([]);
  const [Imgpath, setImgpath] = useState("");
  const [mobileBanner, setMobileBanner] = useState([]);
  const [mobileImgpath, setMobileImgpath] = useState("");
  const [loading, setLoading] = useState();
  const [bookAppointmentModal, setBookAppointmentModal] = useState(false);
  const handleClose = () => {
    setBookAppointmentModal(false);
  };
  const didMountRef = useRef(true);
  useEffect(() => {
    if (didMountRef.current) {
      getTopSliderData();
      getMobileSliderData()
    }
    didMountRef.current = false;
  });
  const getSliderData = () => {
    setLoading(true);
    ApiService.Commanfetchrequest("slider-data").then((response) => {
      if (response.status == "success") {
        setbannerImg(response?.sliderData);
        setImgpath(response?.slider_image_path);
        setLoading(false);
      }
    });
  };
  const getTopSliderData = () => {
    setLoading(true);
    ApiService.Commanfetchrequest("home-top-slider-data").then((response) => {
      if (response.status == "success") {
        setbannerImg(response?.sliderData);
        setImgpath(response?.slider_image_path);
        setLoading(false);
      }
    });
  };
  const getMobileSliderData = () => {
    setLoading(true);
    ApiService.Commanfetchrequest("home-top-mobile-slider-data").then((response) => {
      if (response.status == "success") {
        setMobileBanner(response?.sliderData);
        setMobileImgpath(response?.slider_image_path);
      }
    });
  };

  return (
    <>
      <BrowserView>
        {loading == true ? (
          <>
            <div className="homeswiper">
              <Swiper
                spaceBetween={0}
                slidesPerView={1}
                
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
              >
                <SwiperSlide>
                  <div className="homeSlider">
                    <Skeleton width={"100%"} height={550} />
                    <div className="homeSlider-content">
                      <div>
                        <span class="pre-title"></span>
                        <h2>Director & Incharge- Structural</h2>
                        <h3>Heart Disease Programme</h3>
                        <p></p>

                        <Skeleton width={"50%"} height={60} />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </>
        ) : (
          <>
            {bannerImg?.length > 0 ? (
              <>
                <div className="homeswiper">
                  <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => console.log(swiper)}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    modules={[Autoplay]}
                  >
                    {bannerImg?.map((items, index) => {
                      return (
                        <>
                          <SwiperSlide key={index}>
                            <div className="homeSlider">
                              <img src={Imgpath + items?.slider_image}></img>
                              <div className="homeSlider-content">
                                <div>
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: items.slider_desc,
                                    }}
                                  ></p>
                                  <a
                                    class="edu-btn btn-medium mt-4"
                                    href="javascript:void(0)"
                                    onClick={() =>
                                      setBookAppointmentModal(true)
                                    }
                                  >
                                    Book Appointment <i class="icon-4"></i>
                                  </a>
                                  <a
                                    class="edu-btn btn-medium mt-2"
                                    href="/contact-us"
                                    style={{ marginLeft: "15px" }}
                                  >
                                    Contact Us <i class="icon-4"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </SwiperSlide>
                        </>
                      );
                    })}
                  </Swiper>
                </div>
          
              </>
            ) : (
              ""
            )}
          </>
        )}
      </BrowserView>

      <MobileView>
      {loading == true ? (
          <>
            <div className="homeswiper">
              <Swiper
                spaceBetween={0}
                slidesPerView={1}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
              >
                <SwiperSlide>
                  <div className="homeSlider">
                    <Skeleton width={"100%"} height={550} />
                    <div className="homeSlider-content">
                      <div>
                        <span class="pre-title"></span>
                        <h2>Director & Incharge- Structural</h2>
                        <h3>Heart Disease Programme</h3>
                        <p></p>

                        <Skeleton width={"50%"} height={60} />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </>
        ) : (
          <>
            {mobileBanner?.length > 0 ? (
              <>
                <div className="homeswiper">
                  <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    modules={[Autoplay]}
                  >
                    {mobileBanner?.map((items, index) => {
                      return (
                        <>
                          <SwiperSlide key={index}>
                            <div className="homeSlider">
                              <img src={mobileImgpath + items?.slider_image}></img>
                              <div className="homeSlider-content">
                                <div>
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: items.slider_desc,
                                    }}
                                  ></p>
                                  <a
                                    class="edu-btn btn-medium mt-4"
                                    href="javascript:void(0)"
                                    onClick={() =>
                                      setBookAppointmentModal(true)
                                    }
                                  >
                                    Book Appointment <i class="icon-4"></i>
                                  </a>
                                  <a
                                    class="edu-btn btn-medium mt-2"
                                    href="/contact-us"
                                    style={{ marginLeft: "15px" }}
                                  >
                                    Contact Us <i class="icon-4"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </SwiperSlide>
                        </>
                      );
                    })}
                  </Swiper>
                </div>

              </>
            ) : (
              ""
            )}
          </>
        )}

      </MobileView>


      <Book_AppointmentModal
        show={bookAppointmentModal}
        handleClose={handleClose}
      />
    </>
  );
}

export default HomeBanner;
