import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { BrowserView, MobileView } from "react-device-detect";
import { ApiService } from "../../components/services/apiServices";
import { useParams } from "react-router-dom";
import constants from "../../components/services/constants";
import { Helmet } from "react-helmet";

function Cardic_Intervention() {
  const { slug } = useParams();
  const didMountRef = useRef(true);
  const [cardiacDetails, setCardiacDetails] = useState({});
  const [headerimg, setheaderimg] = useState({});
   const[imgurl, setimgUrl] = useState("");

  useEffect(() => {
    if (didMountRef.current) {
      CardiacDetails();
    }
    didMountRef.current = false;
  });

  const CardiacDetails = () => {
    const cardiacData = {
      cardiac_slug: slug,
    };
    ApiService.Commanpostrequest("cardiac-details", cardiacData).then((res) => {
       
      if (res.status == "success") {
        setCardiacDetails(res.cardiacDetails);
        setheaderimg(res.cardiacDetails.page_header_image)
        setimgUrl(res.header_image_path)
      }
    });
  };



  return (
    <>
                <Helmet>
        <title>{cardiacDetails.page_meta_title}</title>
        <meta name="description" itemprop="description" content={cardiacDetails.page_meta_desc != null ? cardiacDetails.page_meta_desc :"Dr Prashant Dwivedi"} />
        {cardiacDetails.page_meta_keyword != null ?<meta name="keywords" content={cardiacDetails.page_meta_keyword} />:"Dr Prashant Dwivedi"}
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={cardiacDetails.page_meta_title} />
        <meta name="twitter:url" content={window.location.href} />
        <meta property="og:image"  content={headerimg ? imgurl + headerimg: constants.META_DEFAULT_IMAGE}/>
        <meta property="og:url" content={window.location.href} />
        {cardiacDetails.page_meta_desc != null ?<meta property="og:description" content={cardiacDetails.page_meta_desc} />:"Dr Prashant Dwivedi"}
        <meta name="twitter:title" content={cardiacDetails.page_meta_title} />
        {cardiacDetails.page_meta_desc != null ?<meta name="twitter:description" content={cardiacDetails.page_meta_desc} />:"Dr Prashant Dwivedi"}
        <meta property="twitter:image"  content={headerimg ? imgurl + headerimg: constants.META_DEFAULT_IMAGE}/>
        
      </Helmet>
      <Header />

      <div className="subheader" style={{backgroundImage: `url(${imgurl +  headerimg})`}}>
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12">
                    <h1>{cardiacDetails.page_name}</h1>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a></li>
                            <li class="breadcrumb-item active" aria-current="page">{cardiacDetails.page_name}</li>
                        </ol>
                        </nav>
                </div>
            </div>
        </div>
    </div>
        <section className="section-gap-md">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
              <p dangerouslySetInnerHTML={{ __html: cardiacDetails.page_content }}></p>
              </div>
            </div>
          </div>
        </section>
     

      <Footer />
    </>
  );
}

export default Cardic_Intervention;
