import { useCallback, useEffect, useRef, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { Swiper, SwiperSlide } from "swiper/react";
import { ApiService } from "../../services/apiServices";
import constants from "../../services/constants";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import YouTube from "react-youtube";
import { Autoplay } from "swiper/modules";

function HomeTestimonial() {
  const [testimonialdata, settestimonialdata] = useState([]);
  const [videoData, setVideoData] = useState([]);

  const [imagepath, setImagepath] = useState("");
  const [loading, setLoading] = useState();

  const didMountRef = useRef(true);
  useEffect(() => {
    if (didMountRef.current) {
      getSliderData();
      videoTestinomials();
    }
    didMountRef.current = false;
  });
  const getSliderData = () => {
    setLoading(true);
    ApiService.Commanfetchrequest("testimonialsfeatured").then((response) => {
      if (response.status == "success") {
        settestimonialdata(response?.data);
        setImagepath(response?.IMAGE_URL);
        setLoading(false);
      }
    });
  };
  const videoTestinomials = () => {
    ApiService.Commanfetchrequest("video-testimonials").then((response) => {
      
      if (response.status == "success") {
        setVideoData(response.testimonialdata);
      }
    });
  };
  const opts = {
    height: "300px",
    width: "100%",
  };

  const sliderRef = useRef(null);
  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  const sliderRef1 = useRef(null);
  const handlePrev1 = useCallback(() => {
    if (!sliderRef1.current) return;
    sliderRef1.current.swiper.slidePrev();
  }, []);

  const handleNext1 = useCallback(() => {
    if (!sliderRef1.current) return;
    sliderRef1.current.swiper.slideNext();
  }, []);
  const generateRatingIcons = (rating) => {
    const icons = [];
    for (let i = 0; i < rating; i++) {
      icons.push(<i key={i} className="icon-23"></i>);
    }
    return icons;
  };
  return (
    <>
      <BrowserView>
        {loading == true ? (
          <>
            <section className="section-gap-md pt-0 testiomnial">
              <div className="container">
                <div className="row">
                  <div className="col-lg-5">
                    <div className="section-title">
                      <span class="pre-title">
                        <Skeleton width={"100%"} height={20} />
                      </span>
                      <h2>
                        <Skeleton width={"100%"} height={40} />
                      </h2>
                      <span className="shape-line">
                        <i className="icon-19"></i>
                      </span>
                      <p>
                        <Skeleton width={"100%"} height={100} />
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <Swiper
                      spaceBetween={0}
                      slidesPerView={2}
                     
                    >
                      {[...Array(2)].map((_, index) => (
                        <>
                          <SwiperSlide>
                            <div className="testimonial-grid" key={index}>
                              <div className="thumbnail"></div>
                              <div className="content">
                                <p>
                                  {" "}
                                  <Skeleton width={"100%"} height={200} />
                                </p>
                                <div className="rating-icon">
                                  <Skeleton width={60} height={14} />
                                </div>
                                <h5 className="title">
                                  {" "}
                                  <Skeleton width={90} height={14} />
                                </h5>
                                <span className="subtitle">
                                  {" "}
                                  <Skeleton width={40} height={14} />
                                </span>
                              </div>
                            </div>
                          </SwiperSlide>
                        </>
                      ))}
                    </Swiper>
                  </div>
                </div>
              </div>
            </section>
          </>
        ) : (
          <>
            {testimonialdata.length > 0 && (
              <section className="section-gap-md pt-0">
                <div className="container-fluid">
                  <div className="row justify-content-center">
                    <div className="col-lg-7">
                      <div className="section-title text-center">
                        <span class="pre-title">Testimonials</span>
                        <h2>What Our Patients Have To Say</h2>
                        <span className="shape-line">
                          <i className="icon-19"></i>
                        </span>
                        <p>
                          We provide primary care when you need it, get
                          personalized and high quality healthcare by talking to
                          top medical doctors.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div className="videotestslide">
                        <Swiper
                          spaceBetween={0}
                          slidesPerView={1}
                          ref={sliderRef1}
                          autoplay={{
                            delay: 4000,
                            disableOnInteraction: false,
                          }}
                          modules={[Autoplay]}
                        >
                          {videoData?.map((value, index) => {
                            return (
                              <SwiperSlide>
                                <div className="videotestibox">
                                  <YouTube
                                    videoId={value.testimonial_video}
                                    opts={opts}
                                  />
                                  <div className="videotestibox-content">
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: value?.testimonial_desc,
                                      }}
                                    ></p>
                                  </div>
                                </div>
                              </SwiperSlide>
                            );
                          })}
                        </Swiper>
                        <div className="swiper-buttons__box">
                          <div
                            className="swiper-button swiper-button-prev"
                            style={{ alignItems: "center" }}
                            onClick={handlePrev1}
                          >
                            <span>
                              <svg
                                width="27"
                                height="22"
                                viewBox="0 0 32 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M2 14L26 14"
                                  stroke="currentColor"
                                  strokeWidth="3"
                                  strokeLinecap="square"
                                ></path>
                                <path
                                  d="M17.6514 3L29.6514 14L17.6514 25"
                                  stroke="currentColor"
                                  strokeWidth="3"
                                  strokeLinecap="square"
                                ></path>
                              </svg>
                            </span>
                          </div>
                          <div
                            className="swiper-button swiper-button-next"
                            onClick={handleNext1}
                            style={{ alignItems: "center" }}
                          >
                            <span>
                              <svg
                                width="27"
                                height="22"
                                viewBox="0 0 32 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M2 14L26 14"
                                  stroke="currentColor"
                                  strokeWidth="3"
                                  strokeLinecap="square"
                                ></path>
                                <path
                                  d="M17.6514 3L29.6514 14L17.6514 25"
                                  stroke="currentColor"
                                  strokeWidth="3"
                                  strokeLinecap="square"
                                ></path>
                              </svg>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="testiomnial tetestslide">
                        <Swiper
                          spaceBetween={0}
                          slidesPerView={2}
                          ref={sliderRef}
                          autoplay={{
                            delay: 4000,
                            disableOnInteraction: false,
                          }}
                          modules={[Autoplay]}
                        >
                          {testimonialdata.map((value, index) => {
                            return (
                              <SwiperSlide>
                                <div className="testimonial-grid" key={index}>
                                  <div className="thumbnail">
                                    <img
                                      src={
                                        value.testimonial_image != null
                                          ? imagepath + value.testimonial_image
                                          : constants.DEFAULT_IMAGE
                                      }
                                      alt="Testimonial"
                                    />
                                    <span className="qoute-icon">
                                      <i className="icon-26"></i>
                                    </span>
                                  </div>
                                  <div className="content">
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: value.testimonial_desc,
                                      }}
                                    ></p>
                                    <div className="rating-icon">
                                    {generateRatingIcons(value.testimonial_rating)}
                                    </div>
                                    <h5 className="title">
                                      {value.testimonial_name}
                                    </h5>
                                    <span className="subtitle">
                                      {value.testimonial_post}
                                    </span>
                                  </div>
                                </div>
                              </SwiperSlide>
                            );
                          })}
                        </Swiper>
                        <div className="swiper-buttons__box">
                          <div
                            className="swiper-button swiper-button-prev"
                            style={{ alignItems: "center" }}
                            onClick={handlePrev}
                          >
                            <span>
                              <svg
                                width="27"
                                height="22"
                                viewBox="0 0 32 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M2 14L26 14"
                                  stroke="currentColor"
                                  strokeWidth="3"
                                  strokeLinecap="square"
                                ></path>
                                <path
                                  d="M17.6514 3L29.6514 14L17.6514 25"
                                  stroke="currentColor"
                                  strokeWidth="3"
                                  strokeLinecap="square"
                                ></path>
                              </svg>
                            </span>
                          </div>
                          <div
                            className="swiper-button swiper-button-next"
                            onClick={handleNext}
                            style={{ alignItems: "center" }}
                          >
                            <span>
                              <svg
                                width="27"
                                height="22"
                                viewBox="0 0 32 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M2 14L26 14"
                                  stroke="currentColor"
                                  strokeWidth="3"
                                  strokeLinecap="square"
                                ></path>
                                <path
                                  d="M17.6514 3L29.6514 14L17.6514 25"
                                  stroke="currentColor"
                                  strokeWidth="3"
                                  strokeLinecap="square"
                                ></path>
                              </svg>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}
          </>
        )}
      </BrowserView>

      <MobileView>
        {loading == true ? (
          <>
            <section className="section-gap-md pt-0 testiomnial">
              <div className="container">
                <div className="row">
                  <div className="col-lg-5">
                    <div className="section-title text-center">
                      <span class="pre-title">
                        <Skeleton width={"100%"} height={50} />
                      </span>
                      <h2>
                        <Skeleton width={"100%"} height={60} />
                      </h2>
                      <span className="shape-line">
                        <i className="icon-19"></i>
                      </span>
                      <p>
                        <Skeleton width={"100%"} height={80} />
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <Swiper
                      spaceBetween={0}
                      slidesPerView={1}
                      
                    >
                      <SwiperSlide>
                        <div className="testimonial-grid">
                          <div className="thumbnail">
                            <Skeleton width={"100%"} borderRadius={"30%"} />
                          </div>
                          <div className="content">
                            <p>
                              <Skeleton width={"100%"} height={200} />
                            </p>
                            <div className="rating-icon">
                              <Skeleton width={"15%"} height={14} />
                            </div>
                            <h5 className="title">
                              <Skeleton width={"20%"} height={14} />
                            </h5>
                            <span className="subtitle">
                              <Skeleton width={"10%"} height={14} />
                            </span>
                          </div>
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
              </div>
            </section>
          </>
        ) : (
          <>
            {testimonialdata.length > 0 && (
              <section className="section-gap-md pt-0 testiomnial">
                <div className="container-fluid">
                  <div className="section-title text-center">
                    <span class="pre-title">Testimonials</span>
                    <h2>What Our Patients Have To Say</h2>
                    <span className="shape-line">
                      <i className="icon-19"></i>
                    </span>
                    <p>
                      We provide primary care when you need it, get personalized
                      and high quality healthcare by talking to top medical
                      doctors.{" "}
                    </p>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="videotestslide">
                        <Swiper
                          spaceBetween={0}
                          slidesPerView={1}
                          ref={sliderRef1}
                          autoplay={{
                            delay: 3500,
                            disableOnInteraction: false,
                          }}
                          modules={[Autoplay]}
                        >
                          {videoData?.map((value, index) => {
                            return (
                              <SwiperSlide>
                                <div className="row" key={index}>
                                  <div className="col-lg-6">
                                    <div className="card card-review text-center mt-3">
                                      <YouTube
                                        videoId={value.testimonial_video}
                                        opts={opts}
                                      />
                                      <p className="review-author">
                                        {value.testimonial_name}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </SwiperSlide>
                            );
                          })}
                        </Swiper>
                        <div className="swiper-buttons__box">
                          <div
                            className="swiper-button swiper-button-prev"
                            style={{ alignItems: "center" }}
                            onClick={handlePrev1}
                          >
                            <span>
                              <svg
                                width="27"
                                height="22"
                                viewBox="0 0 32 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M2 14L26 14"
                                  stroke="currentColor"
                                  strokeWidth="3"
                                  strokeLinecap="square"
                                ></path>
                                <path
                                  d="M17.6514 3L29.6514 14L17.6514 25"
                                  stroke="currentColor"
                                  strokeWidth="3"
                                  strokeLinecap="square"
                                ></path>
                              </svg>
                            </span>
                          </div>
                          <div
                            className="swiper-button swiper-button-next"
                            onClick={handleNext1}
                            style={{ alignItems: "center" }}
                          >
                            <span>
                              <svg
                                width="27"
                                height="22"
                                viewBox="0 0 32 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M2 14L26 14"
                                  stroke="currentColor"
                                  strokeWidth="3"
                                  strokeLinecap="square"
                                ></path>
                                <path
                                  d="M17.6514 3L29.6514 14L17.6514 25"
                                  stroke="currentColor"
                                  strokeWidth="3"
                                  strokeLinecap="square"
                                ></path>
                              </svg>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="testiomnial tetestslide">
                        <Swiper
                          spaceBetween={0}
                          slidesPerView={1}
                          
                          ref={sliderRef}
                          autoplay={{
                            delay: 3500,
                            disableOnInteraction: false,
                          }}
                          modules={[Autoplay]}
                        >
                          {testimonialdata.map((value, index) => {
                            return (
                              <SwiperSlide>
                                <div className="testimonial-grid" key={index}>
                                  <div className="thumbnail">
                                    <img
                                      src={
                                        value.testimonial_image != null
                                          ? imagepath + value.testimonial_image
                                          : constants.DEFAULT_IMAGE
                                      }
                                      alt="Testimonial"
                                    />
                                    <span className="qoute-icon">
                                      <i className="icon-26"></i>
                                    </span>
                                  </div>
                                  <div className="content">
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: value.testimonial_desc,
                                      }}
                                    ></p>
                                    <div className="rating-icon">
                                    {generateRatingIcons(value.testimonial_rating)}
                                    </div>
                                    <h5 className="title">
                                      {value.testimonial_name}
                                    </h5>
                                    <span className="subtitle">
                                      {value.testimonial_post}
                                    </span>
                                  </div>
                                </div>
                              </SwiperSlide>
                            );
                          })}
                        </Swiper>
                        <div className="swiper-buttons__box">
                          <div
                            className="swiper-button swiper-button-prev"
                            style={{ alignItems: "center" }}
                            onClick={handlePrev}
                          >
                            <span>
                              <svg
                                width="27"
                                height="22"
                                viewBox="0 0 32 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M2 14L26 14"
                                  stroke="currentColor"
                                  strokeWidth="3"
                                  strokeLinecap="square"
                                ></path>
                                <path
                                  d="M17.6514 3L29.6514 14L17.6514 25"
                                  stroke="currentColor"
                                  strokeWidth="3"
                                  strokeLinecap="square"
                                ></path>
                              </svg>
                            </span>
                          </div>
                          <div
                            className="swiper-button swiper-button-next"
                            onClick={handleNext}
                            style={{ alignItems: "center" }}
                          >
                            <span>
                              <svg
                                width="27"
                                height="22"
                                viewBox="0 0 32 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M2 14L26 14"
                                  stroke="currentColor"
                                  strokeWidth="3"
                                  strokeLinecap="square"
                                ></path>
                                <path
                                  d="M17.6514 3L29.6514 14L17.6514 25"
                                  stroke="currentColor"
                                  strokeWidth="3"
                                  strokeLinecap="square"
                                ></path>
                              </svg>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}
          </>
        )}
      </MobileView>
    </>
  );
}

export default HomeTestimonial;
