import { BrowserView, MobileView } from "react-device-detect";
import { Swiper, SwiperSlide } from "swiper/react";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { ApiService } from "../../services/apiServices";
import constants from "../../services/constants";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const HomeBlogs = () => {
  const defaultImg = constants.DEFAULT_IMAGE;
  const [Blogdata, setBlogdata] = useState([]);
  const [ImgUrl, setImgUrl] = useState("");
  const [loading, setLoading] = useState();
  const didMountRef = useRef(true);

  useEffect(() => {
    if (didMountRef.current) {
      blogList();
    }
    didMountRef.current = false;
  }, []);

  const blogList = () => {
    setLoading(true);
    ApiService.Commanfetchrequest("newsandblogslistfeatured").then((res) => {
      
      if (res?.status == "success") {
        setBlogdata(res?.data);
        setImgUrl(res?.imgeURL);
        setLoading(false);
      }
    });
  };

  const sliderRef = useRef(null);
  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <>
      {loading == true ? (
        <>
          <section
            className="section-gap-md blog-section"
            style={{ background: "rgb(246, 247, 248)" }}
          >
            <div className="container-fluid">
              <div className="section-title section-center">
                <h2 className="tx-white">Dr. Prashant Dwivedi Blog</h2>
                <span class="shape-line">
                  <i class="icon-19"></i>
                </span>
              </div>

              <Swiper
                spaceBetween={15}
                slidesPerView={4}
                ref={sliderRef}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },
                  400: {
                    slidesPerView: 1,
                  },
                  639: {
                    slidesPerView: 2,
                  },
                  865: {
                    slidesPerView: 2,
                  },
                  1000: {
                    slidesPerView: 4,
                  },
                  1500: {
                    slidesPerView: 4,
                  },
                  1700: {
                    slidesPerView: 4,
                  },
                }}
              >
                {[...Array(4)].map((_, index) => {
                  return (
                    <>
                      <SwiperSlide key={index}>
                        <div className="blog blog-style-1">
                          <div className="thumbnail">
                            <a href="">
                              <Skeleton width={"100%"} height={250} />
                            </a>
                          </div>
                          <div className="content">
                            <div className="read-more-btn">
                              <a class="btn-icon-round" href="">
                                <i class="icon-4"></i>
                              </a>
                            </div>
                            <h5 className="title">
                              <Skeleton width={"100%"} height={30} />
                            </h5>
                            <p>
                              <Skeleton width={"100%"} height={70} />
                            </p>
                          </div>
                        </div>
                      </SwiperSlide>
                    </>
                  );
                })}
              </Swiper>
            </div>
          </section>
        </>
      ) : (
        <>
          {Blogdata?.length > 0 ? (
            <>
              <section
                className="section-gap-md blog-section"
                style={{ background: "rgb(246, 247, 248)" }}
              >
                <div className="container-fluid">
                  <div className="section-title section-center">
                    <span class="pre-title tx-white">Latest News & Blog</span>
                    <h2 className="tx-white">Dr. Prashant Dwivedi Blog</h2>
                    <span class="shape-line tx-white">
                      <i class="icon-19"></i>
                    </span>
                  </div>
                  <Swiper
                    spaceBetween={15}
                    slidesPerView={4}
                    ref={sliderRef}
                    breakpoints={{
                      0: {
                        slidesPerView: 1,
                      },
                      400: {
                        slidesPerView: 1,
                      },
                      639: {
                        slidesPerView: 2,
                      },
                      865: {
                        slidesPerView: 2,
                      },
                      1000: {
                        slidesPerView: 3,
                      },
                      1500: {
                        slidesPerView: 4,
                      },
                      1700: {
                        slidesPerView: 4,
                      },
                    }}
                  >
                    {Blogdata?.map((items, index) => {
                      return (
                        <>
                          <SwiperSlide>
                            <div className="blog blog-style-1" key={index}>
                              <div className="thumbnail">
                                <a href={`/blogs/${items?.blog_slug}`}>
                                  <img
                                    src={
                                      items?.blog_image !== null
                                        ? ImgUrl + items?.blog_image
                                        : defaultImg
                                    }
                                  ></img>
                                </a>
                              </div>
                              <div className="content">
                                <div className="read-more-btn">
                                  <a
                                    class="btn-icon-round"
                                    href={`/blogs/${items?.blog_slug}`}
                                  >
                                    <i class="icon-4"></i>
                                  </a>
                                </div>
                                <h5 className="title">
                                  <a href={`/blogs/${items?.blog_slug}`}>
                                    {items?.blog_name}
                                  </a>
                                </h5>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: items.blog_short_description,
                                  }}
                                ></p>
                              </div>
                            </div>
                          </SwiperSlide>
                        </>
                      );
                    })}
                  </Swiper>
           
                </div>
              </section>
            </>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
};

export default HomeBlogs;
