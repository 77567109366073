import axios from 'axios';
import constant from './constants'; 
const client = axios.create({
  baseURL: constant.API_URL,
});

export class ApiService {
  static async Commanfetchrequest(url) {
    try {
      const response = await client.get(url);
      return response.data;
    } 
    catch (error) {
      // if(error){
       
      //   localStorage.removeItem("USER_SESSION")
      //    window.location.href="/"
      // }
      console.error('Error fetching data:', error?.status);
    
    }
  }

  static async Commanpostrequest(url,data) {
    try {
      const response = await client.post(url, data);
      return response.data;
    } catch (error) {
      // console.error('Error posting data:', error);
      throw error;
    }
  }

  static async numberFormat(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}
