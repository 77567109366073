import React, { useEffect, useRef, useState } from 'react'
import { ApiService } from '../../components/services/apiServices';
import { useParams } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import constants from '../../components/services/constants';
import { Helmet } from 'react-helmet';

function Pages() {
    const didMountRef = useRef(true);
    const { slug } = useParams();
    const[pageData,setPageData] = useState({})
    const[headerImageUrl,setHeaderImageUrl] = useState('')

    useEffect(()=>{

        if(didMountRef.current){
            getPagesData()
        }
        didMountRef.current = false;
        })
        
      const getPagesData = () =>{
        const dataString ={
            slug: slug,

        }
        ApiService.Commanpostrequest("pages-content",dataString).then((res)=>{
            if(res.status == "success"){
             setPageData(res.data)
             setHeaderImageUrl(res.header_image_path)

            }
        })
      }  


  return (
    <>
        <Helmet>
        <title>{pageData.page_meta_title}</title>
        <meta name="description" itemprop="description" content={pageData.page_meta_desc != null ? pageData.page_meta_desc :"Dr Prashant Dwivedi"} />
        {pageData.page_meta_keyword != null ?<meta name="keywords" content={pageData.page_meta_keyword} />:"Dr Prashant Dwivedi"}
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={pageData.page_meta_title} />
        <meta name="twitter:url" content={window.location.href} />
        <meta property="og:image" content={pageData.page_header_image ? headerImageUrl + pageData.page_header_image: constants.META_DEFAULT_IMAGE}/>
        <meta property="og:url" content={window.location.href} />
        {pageData.page_meta_desc != null ?<meta property="og:description" content={pageData.page_meta_desc} />:"Dr Prashant Dwivedi"}
        <meta name="twitter:title" content={pageData.page_meta_title} />
        {pageData.page_meta_desc != null ?<meta name="twitter:description" content={pageData.page_meta_desc} />:"Dr Prashant Dwivedi"}
        <meta property="twitter:image"  content={pageData.page_header_image ? headerImageUrl + pageData.page_header_image: constants.META_DEFAULT_IMAGE}/>
        
      </Helmet>
    <Header/>
    <div className="subheader" style={{backgroundImage: `url(${headerImageUrl + pageData.page_header_image})`}}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <h1>{pageData.page_name}</h1>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                    {pageData.page_name}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
    {pageData.page_content != null ?(
     <div dangerouslySetInnerHTML={{__html:pageData?.page_content}}></div>
     ) : (
      ""
    )}
     <Footer/>
    </>
   
  )
}

export default Pages