import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate, Switch } from 'react-router-dom';
import 'swiper/css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Home from './conatiner/Home';
import Treatments from './conatiner/Treatments';
import AboutUs from './conatiner/About us';
import Cardic_Intervention from './conatiner/CardicIntervention';
import Blogs from './conatiner/Blogs';
import Contact from './conatiner/Contact';
import BlogsDetail from './conatiner/Blogs-details';
import Gallery from './conatiner/Gallery';
import Pages from './conatiner/Pages';
import Videos from './conatiner/Videos';
import Text from './conatiner/Testimonial/text';
import Video from './conatiner/Testimonial/video';
import CardiologistJaipur from './conatiner/CardiologistJaipur';
import TAVRExpertJaipur from './conatiner/TAVR_Expert_Jaipur';


function App() {
  return (
    <div className="App">
      <ToastContainer />
      <Router>
        <Routes>
          <Route exact path='/' activeClassName="active" element={<Home />} />
          <Route exact path='/treatments' activeClassName="active" element={<Treatments />} />
          <Route exact path='/treatments/:slug' activeClassName="active" element={<Treatments />} />
          <Route exact path='/about' activeClassName="active" element={<AboutUs />} />
          <Route exact path='/gallery' activeClassName="active" element={<Gallery />} />
          <Route exact path='?gallery/videos' activeClassName="active" element={<Videos />} />
          <Route exact path='/cardiac/:slug' activeClassName="active" element={<Cardic_Intervention />} />
          <Route exact path='/blogs' activeClassName="active" element={<Blogs />} />
          <Route exact path='/blogs/:type/:slug' activeClassName="active" element={ <Blogs /> }/>
          <Route exact path='/cardiologist-in-jaipur' activeClassName="active" element={<CardiologistJaipur />} />
          <Route exact path='/treatments/tavrtavi-expert-in-jaipur-rajasthan' activeClassName="active" element={<TAVRExpertJaipur />} />


          <Route exact path='/contact-us' activeClassName="active" element={<Contact />} />
          <Route exact path='/blogs/:slug' activeClassName="active" element={<BlogsDetail />} />
          <Route exact path='/about/text' activeClassName="active" element={<Text />} />
          <Route exact path='/about/video' activeClassName="active" element={<Video/>} />
          <Route exact path='/:slug' activeClassName="active" element={<Pages />} />
          

        </Routes>
      </Router>

    </div>
  );
}

export default App;
